// import UserForm from "../../components/UserForm";
import {
  Container,
  // Header
} from './styles';
import UsersService from '../../services/UsersService';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import Config from '../../config/environments/local';
import Button from '../../components/Button';
import { ButtonContainer } from './styles';
import Loader from '../../components/Loader';
import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'
import BigLogo from '../../assets/icons/menu/big-logo.svg'

export default function UserConfirmation() {

  const { code } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isActivateUserSucess, setIsActivateUserSucess] = useState(false);

  async function handleGoToLogin() {
    window.open('/login', '_self');
  }

  async function activateUser() {

    const response = await new UsersService(Config.API_BASE_URL).ActivateUser({
      Code: code
    });

    if (response.Result === true) {
      setIsActivateUserSucess(true);
    }
    else {

    }

    setIsLoading(false);
  }

  if ((!isLoading) && (!isActivateUserSucess)) {
    setIsLoading(true);
    activateUser();
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <Container>
        <div className='info-page'>
          <div className="big-logo-container">
            <img src={BigLogo} alt='logo' />
          </div>
          <div className="image-header">
            <img src={CalendarImage} alt='telembro' />
          </div>
          <div className="text-header">
            {(isActivateUserSucess) ? (
              <strong>
                Cadastro ativado <br />com sucesso
              </strong>
            ) : (
              <strong>
                Ativação de cadastro
              </strong>
            )}
          </div>
        </div>

        {(isActivateUserSucess) && (
          <ButtonContainer>
            <Button
              type="button"
              onClick={(e) => handleGoToLogin(e)}
            >
              Clique aqui para fazer login
            </Button>
          </ButtonContainer>
        )}
      </Container>
    </>
  )
};