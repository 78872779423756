import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import {
  Overlay,
  // Container, Footer
} from './styles';
import Button from '../Button';
import CloseModalButton from '../../assets/icons/close-modal.svg'
import WhiteCloseModalButton from '../../assets/icons/close-modal-white.svg'
import Attachment from '../../assets/icons/attach-file.svg';

export default function ModalAttachments({ title, attachmentUrls, tip, onConfirm, onCancel, isVisible }) {

  function handleCancel() {
    onCancel();
  }

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Overlay>
        <div
          // danger={tip}
          className={`modal-container ${tip && 'tip'}`}
        >
          <div className='modal-title'>
            <div className='text'>{title}</div>
            <div className='close-button'>
              <button
                onClick={handleCancel}
              >
                {tip ?
                  (
                    <img src={WhiteCloseModalButton} alt='telembro' />
                  ) :
                  (
                    <img src={CloseModalButton} alt='telembro' />
                  )}
              </button>
            </div>
          </div>
          <div className='modal-body'>
            {/* {body} */}
            {attachmentUrls && (
              attachmentUrls.map((attachmentUrl, index) => (
                <>
                  <a href={attachmentUrl.FileUrl} target='_blank' rel='noreferrer'>
                    <img src={Attachment} alt='Attachment' />
                    Anexo {index + 1}
                  </a>
                  <br />
                  <br />
                </>
              ))
            )}
          </div>

          {!tip && (
            <div
              className='button-container'
            >
              <Button
                type='button'
                onClick={onConfirm}
                className='button'
              >
                Fechar
              </Button>
            </div>
          )}

        </div>
      </Overlay>
    </>,
    document.getElementById('modal-root')
  );
}

ModalAttachments.propTypes = {
  title: PropTypes.string.isRequired,
  // body: PropTypes.string.isRequired,
  attachmentUrls: PropTypes.array.isRequired,
  isVisible: PropTypes.bool,
  tip: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

ModalAttachments.defaultProps = {
  tip: false,
  isVisible: false,
}