import HttpClient from './utils/HttpClient';

class SharedAppointmentMessagesService {
  constructor(baseUrl) {

    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async listAppointments(userId) {
    // const urlPath = '/shared-appointment-messages/get-all{}';
    const urlPath = `/shared-appointment-messages/get-all/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async getAppointment(id) {
    const urlPath = `/shared-appointment-messages/get/${id}`;

    return this.httpClient.get(urlPath);
  }

  async get(appointmentId, ownerUserId) {
    const urlPath = `/shared-appointment-messages/get/${appointmentId}/viewer-user/${ownerUserId}`;

    return this.httpClient.get(urlPath);
  }

  async delete(request) {
    const urlPath = `/shared-appointment-messages/delete`;

    return this.httpClient.post(urlPath, request);
  }

  async send(request) {
    const urlPath = `/shared-appointment-messages/send`;

    console.log(request);

    return this.httpClient.post(urlPath, request);
  }

  async getAllPendingOfApproval(userId) {
    const urlPath = `/shared-appointment-messages/get-all-pending-approval/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async getAllApproved(userId) {
    const urlPath = `/shared-appointment-messages/get-all-approved/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async getSinglePendingOfApproval(userId, appointmentId) {
    const urlPath = `/shared-appointment-messages/get-single-pending-approval/${userId}/${appointmentId}`;

    return this.httpClient.get(urlPath);
  }

  async getSharedWithEmails(userId) {
    const urlPath = `/shared-appointment-messages/shared-with-emails/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async manageApproval(request) {
    const urlPath = `/shared-appointment-messages/manage-approval`;

    console.log(request);

    return this.httpClient.post(urlPath, request);
  }

  async removeApproval(request) {
    const urlPath = `/shared-appointment-messages/remove-approval`;

    console.log(request);

    return this.httpClient.post(urlPath, request);
  }
}

export default SharedAppointmentMessagesService;