import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import {
  Overlay,
} from './styles';
import Button from '../Button';
// import Input from '../Input';
import CloseModalButton from '../../assets/icons/close-modal.svg'
import AppVersionService from '../../services/AppVersionService';
import Config from '../../config/environments/local';
import { localGet, localSet } from '../../lib/session';
import {
  useState,
  useEffect,
} from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   // faCalendarDay,
//   faDownload,
// } from '@fortawesome/free-solid-svg-icons';

export default function ModalAppVersion({ title, danger, onCancel, isVisible }) {
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  // const [sharedEmails, setSharedEmails] = useState([]);
  const [appVersion, setAppVersion] = useState(null);
  // const [items, setItems] = useState([]);
  // const [value, setValue] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);


  useEffect(() => {
    async function geAppVersion(e) {

      const listAppVersion = await new AppVersionService(Config.API_BASE_URL).getAllAppVersion(sessionObject.userId);

      // console.log(getSharedWithEmails);

      if (listAppVersion.Error) {
        alert(listAppVersion.Error.Message);
        console.log('Ocorreu um erro ao recuperar a versão do app');
      }
      else {
        // console.log(getSharedWithEmails.Result);
        setAppVersion(listAppVersion.Result);
      }
    }

    geAppVersion();
  }, [sessionObject.userId]);

  function handleCancel() {
    onCancel();
  }

  async function handleConfirm() {
    const acceptAppVersion = await new AppVersionService(Config.API_BASE_URL).acceptAppVersion(sessionObject.userId, appVersion.Id);

    // console.log(getSharedWithEmails);

    if (acceptAppVersion.Error) {
      alert(acceptAppVersion.Error.Message);
      console.log('Ocorreu um erro ao aceitar a versão do app');
    }
    else {
      // console.log(getSharedWithEmails.Result);
      // setAppVersion(acceptAppVersion.Result);
      localSet(Config.APPVERSION_KEY, true, Config.EXPIRATION_AUTH);
      onCancel();
    }
  }

  // function EnableButton() {
  //   setIsButtonEnabled(true);
  // }

  function handleScroll(event) {
    // onCancel();

    const target = event.target;
    if (target.scrollHeight - target.scrollTop <= (target.clientHeight + 500)) {
      // alert("scrolled");
      setIsButtonEnabled(true);
    }
  }

  if (!isVisible || (!appVersion)) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Overlay>
        {(appVersion && (
          <div danger={danger}
            className='modal-container full'
          >
            <div className='modal-title'>
              <div className='text'>{`${appVersion.VersionNumber}`}</div>
              <div className='close-button'>
                <button
                  onClick={handleCancel}
                >
                  <img src={CloseModalButton} alt='telembro' />
                </button>
              </div>
            </div>
            <div className='modal-body full'>
              <div className='terms-content' dangerouslySetInnerHTML={{ __html: (appVersion.VersionDetails) }}
                onScroll={handleScroll}
              />
            </div>
            <div
              className='button-container'
            >
              <Button
                type='button'
                // danger={danger}
                // onClick={onConfirm}
                onClick={handleConfirm}
                // disabled={!isEmailValid(emailValue)}
                disabled={!isButtonEnabled}
                className='button'
              >
                Entendido
              </Button>
            </div>
          </div>
        ))}
      </Overlay>
    </>,
    document.getElementById('modal-root')
  );
}

ModalAppVersion.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  danger: PropTypes.bool,
  onCancel: PropTypes.func,
}

ModalAppVersion.defaultProps = {
  danger: false,
  isVisible: false,
}