import HttpClient from './utils/HttpClient';

class AppVersionService {
  constructor(baseUrl) {

    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async getAllAppVersion(userId) {
    const urlPath = `/appversion/get-all/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async listAppVersion() {
    const urlPath = `/appversion/list-all`;

    return this.httpClient.get(urlPath);
  }

  async getById(id) {
    const urlPath = `/appversion/get-by-id/${id}`;

    return this.httpClient.get(urlPath);
  }

  async acceptAppVersion(userId, appVersionId) {
    const urlPath = `/appversion/accept`;

    return this.httpClient.post(urlPath, {
      UserId: userId,
      AppVersionId: appVersionId
    });
  }

  async createAppVersion(versionNumber, versionDetails, isCurrentVersion) {
    const urlPath = `/appversion/add`;

    return this.httpClient.post(urlPath, {
      VersionNumber: versionNumber,
      VersionDetails: versionDetails,
      IsCurrentVersion: isCurrentVersion,
    });
  }

  async editAppVersion(id, versionNumber, versionDetails, isCurrentVersion) {
    const urlPath = `/appversion/update`;

    return this.httpClient.post(urlPath, {
      Id: id,
      VersionNumber: versionNumber,
      VersionDetails: versionDetails,
      IsCurrentVersion: isCurrentVersion,
    });
  }

  async delete(id) {
    const urlPath = `/appversion/delete`;

    return this.httpClient.post(urlPath, {
      Id: id,
      VersionNumber: '',
      VersionDetails: '',
      IsCurrentVersion: false,
    });
  }
}

export default AppVersionService;