import HttpClient from './utils/HttpClient';

class TermsAndConditionsService {
  constructor(baseUrl) {
    
    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async listTermsAndConditions(userId) {
    const urlPath = `/termsandconditions/get-all/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async acceptTermsAndConditions(userId, termsAndConditionsId) {
    const urlPath = `/termsandconditions/accept`;

    return this.httpClient.post(urlPath, {
      UserId: userId,
      TermsAndConditionsId: termsAndConditionsId
    });
  }
}

export default TermsAndConditionsService;