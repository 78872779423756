/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Container,
  // Header,
  Card,
  ErrorContainer,
  CardsContainer,
  CheckboxContainer,
  // CardAndCheckboxContainer,
  // CheckboxAllContainer,
} from './styles';
import Trash from '../../assets/icons/trash.svg';
import Edit from '../../assets/icons/edit.svg';
import Attachment from '../../assets/icons/attach-file.svg';
import Share from '../../assets/icons/share.svg';
import Calendar from '../../assets/icons/event.svg';
import Notifications from '../../assets/icons/notifications.svg';
import Info from '../../assets/icons/info.svg';
import Loader from '../Loader';
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { format } from 'date-fns';
import AppointmentsService from '../../services/AppointmentsService';
// import SharedAppointmentsService from '../../services/SharedAppointmentsService';
import Button from '../Button';
import Modal from '../Modal';
import ModalOptions from '../ModalOptions';
import ModalShareEmail from '../ModalShareEmail';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
// import PlusSign from '../../assets/icons/plus-sign.png';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   // faFolder,
//   // faCalendarDay,
//   // faBell,
//   // faInfoCircle,
//   // faRecycle,
//   // faDollarSign,
// } from '@fortawesome/free-solid-svg-icons'
import SharedAppointmentsService from '../../services/SharedAppointmentsService';
// import Checkbox from '../Checkbox';

// // https://fontawesome.com/v5/cheatsheet

export default function SharedAppointments({ fetchOnlyActive }) {
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  // const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(true);
  // const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  // const [appointmentToShare, setAppointmentToShare] = useState(null);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  // const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedAppointments, setCheckedAppointments] = useState([]);
  // const [sharedEmail, setSharedEmail] = useState('');
  const [sharedEmail, setSharedEmail] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  // const [appointmentToShare, setAppointmentToShare] = useState(null);
  // const [isOptionsVisible, setOptionsVisible] = useState(false);

  const listAppointments = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      const appointmentsList = await new AppointmentsService(Config.API_BASE_URL).listAppointmentsNotShared(sessionObject.userId, fetchOnlyActive);
      setAppointments(appointmentsList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject.userId, fetchOnlyActive]);

  // function handleChangeEmail(e) {
  //   setSharedEmail(e.target.value);
  // }

  function handleChangeEmail(emails) {
    setSharedEmail(emails);
  }

  async function handleReload() {
    await listAppointments();
  };

  useEffect(() => {
    listAppointments();
  }, [listAppointments]);

  async function handleOpenShareModal(e) {
    setIsShareModalOpen(true);
  }

  async function handleShare(e) {
    setIsShareModalOpen(true);
    setIsLoading(true);

    const ShareAppointmentsResponse = await new SharedAppointmentsService(Config.API_BASE_URL).shareAppointments({
      AppointmentsIds: checkedAppointments,
      SharedUserEmail: sharedEmail,
    });

    console.log(ShareAppointmentsResponse);

    if (ShareAppointmentsResponse.Error) {
      alert("Ocorreu um erro ao compartilhar. Verifique se os e-mails digitados existem");
      console.log('Ocorreu um erro ao compartilhar. Verifique se os e-mails digitados existem');
    }
    else {
      console.log(ShareAppointmentsResponse.Error);
      alert("Compromissos compartilhados com sucesso!");
      window.open('/', '_self');
    }
    // window.open(`/shared/${appointmentToShare.Id}`, '_self');

    setIsLoading(false);
    setIsShareModalOpen(false);
  }

  function handleCloseShareModal(e, appointment) {
    setIsShareModalOpen(false);
  }

  function handleOpenModal(e, appointment) {
    setAppointmentToDelete(appointment);
    setIsModalOpen(true);
  }

  function handleOpenModalOptions(e, appointment) {
    setAppointmentToDelete(appointment);
    setIsModalOptionsOpen(true);
  }

  async function handleDelete(e) {
    setIsModalOpen(false);
    setIsModalOptionsOpen(false);

    await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
      Id: appointmentToDelete.Id,
      OriginalAppointmentId: appointmentToDelete.OriginalAppointmentId ?? appointmentToDelete.Id,
    });

    await handleReload();
  }

  async function handleDeleteRecurrenceOnly(e) {
    setIsModalOpen(false);
    setIsModalOptionsOpen(false);

    await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
      Id: appointmentToDelete.Id,
    });

    await handleReload();
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  function handleCloseModalOptions() {
    setIsModalOptionsOpen(false);
  }

  function handleCheckboxChange(appointmentId) {
    const appointmentAlreadyExists = checkedAppointments.find((checkedAppointmentId) => checkedAppointmentId === appointmentId);

    if (appointmentAlreadyExists) {
      //remove o appointment
      setCheckedAppointments((prevState) => prevState.filter(
        (checkedAppointmentId) => checkedAppointmentId !== appointmentId
      ));
    }
    else {
      //adiciona o appointment
      setCheckedAppointments((prevState) => [
        ...prevState,
        appointmentId
      ]);
    }
  }

  function handleAddSingleApointmentToShare(appointmentId) {
    setCheckedAppointments([appointmentId]);
  }

  // function handleCheckAllCheckboxex(appointmentId, isChecked) {
  //   const appointmentAlreadyExists = checkedAppointments.find((checkedAppointmentId) => checkedAppointmentId === appointmentId);

  //   if (isChecked) {
  //     if (!appointmentAlreadyExists) {
  //       //adiciona o appointment
  //       setCheckedAppointments((prevState) => [
  //         ...prevState,
  //         appointmentId
  //       ]);
  //     }
  //   }
  //   else {
  //     //remove o appointment
  //     setCheckedAppointments((prevState) => prevState.filter(
  //       (checkedAppointmentId) => checkedAppointmentId !== appointmentId
  //     ));
  //   }
  // }

  // function handleIsAllChecked(e) {
  //   setIsAllChecked(e.target.checked);

  //   appointments.map((appointment) => handleCheckAllCheckboxex(appointment.Id, e.target.checked));
  // };

  return (
    <Container>
      <Loader isLoading={isLoading} />

      <ModalShareEmail
        title='E-mail'
        isVisible={isShareModalOpen}
        onConfirm={handleShare}
        onCancel={handleCloseShareModal}
        emailValue={sharedEmail}
        onChangeEmail={handleChangeEmail}
      />

      <Modal
        title='Deletar compromisso'
        body='Deseja realmente deletar este compromisso? '
        isVisible={isModalOpen}
        danger
        onConfirm={handleDelete}
        onCancel={handleCloseModal}
      />

      <ModalOptions
        title='Deletar compromisso'
        body='Deseja realmente deletar este compromisso?
        Ele possui recorrências!'
        isVisible={isModalOptionsOpen}
        danger
        onCancel={handleCloseModalOptions}
        onOption1={handleDelete}
        onOption2={handleDeleteRecurrenceOnly}
        option1Text='Deletar todos'
        option2Text='Deletar esta ocorrência'
      />

      {hasError && (
        <ErrorContainer>
          <strong>
            Ocorreu um erro ao retornar os compromissos
          </strong>
          <Button
            type="button"
            onClick={(e) => { handleReload(); }}
          >
            Tentar novamente
          </Button>
        </ErrorContainer>
      )}

      {!hasError && (
        <>
          {/* <CheckboxAllContainer
          // className='toggleWrapper'
          >
            Marcar todos &nbsp;&nbsp;&nbsp;
            <input
              id='checkall'
              type="checkbox"
              checked={isAllChecked}
              onChange={handleIsAllChecked}
              className='mobileToggle'
            />
            <label htmlFor='checkall'></label>
          </CheckboxAllContainer> */}
          <CardsContainer>
            {appointments && (
              appointments.map((appointment) => (
                <Card
                  key={`id-${appointment.Id}`}
                >
                  <div className="info">
                    <div className="text">
                      {/* <div className="appointment-name">
                      <strong>{appointment.AppointmentCategoryName}</strong>
                    </div> */}

                      {appointment.Name && (
                        <div className="span type">
                          <b>{appointment.Name}</b>
                        </div>
                      )}

                      <div className="span type">
                        {appointment.AppointmentTypeName}
                      </div>

                      <div className="span">
                        {/* <FontAwesomeIcon icon={faCalendarDay} /> */}
                        <img src={Calendar} alt='date' />
                        {format(new Date(appointment.AppointmentDate), 'dd/MM/yyyy')}

                        {appointment.AppointmentTime && (
                          ` - ${appointment.AppointmentTime}`
                        )}
                      </div>

                      {(appointment.ReminderDates && appointment.ReminderDates[0]) && (
                        <div className="span">
                          {
                            appointment.ReminderDates[0] && (
                              <>
                                {/* <FontAwesomeIcon icon={faBell} /> */}
                                <img src={Calendar} alt='date' />
                                {format(new Date(appointment.ReminderDates[0]), 'dd/MM/yyyy')}
                              </>
                            )
                          }

                          {
                            appointment.ReminderDates[1] && (
                              <>
                                , {format(new Date(appointment.ReminderDates[1]), 'dd/MM/yyyy')}
                              </>
                            )
                          }

                          {
                            appointment.ReminderDates[2] && (
                              <>
                                &nbsp;e {format(new Date(appointment.ReminderDates[2]), 'dd/MM/yyyy')}
                              </>
                            )
                          }

                        </div>
                      )}

                      {/* {
                      (appointment.DocumentValue !== 0) && (appointment.DocumentValue) && (
                        <>
                          <div className="span">
                            {appointment.DocumentValuestring}
                          </div>
                        </>
                      )
                    } */}

                      {appointment.Observations && (
                        <div className="span">
                          <img src={Info} alt='Info' />
                          {appointment.Observations}
                        </div>
                      )}

                      {appointment.AppointmentHasRecurrences && (
                        <div className="span">
                          <img src={Notifications} alt='Loop' />
                          Recorrente
                        </div>
                      )}

                    </div>
                    <div className="share">
                      <CheckboxContainer
                        className='toggleWrapper'
                      >
                        <input
                          id={appointment.Id}
                          type="checkbox"
                          // className='mobileToggle'
                          key={appointment.Id}
                          checked={(checkedAppointments.includes(appointment.Id))}
                          onChange={(e) => {
                            handleCheckboxChange(appointment.Id);
                          }}
                        />
                        <label htmlFor={appointment.Id}></label>
                      </CheckboxContainer>
                      {(!appointment.SharedAppointmentId) && (
                        <>
                          <button
                            type="button"
                            onClick={(e) => {
                              handleAddSingleApointmentToShare(appointment.Id);
                              handleOpenShareModal(e, appointment);
                            }}
                            onKeyDown={(e) => {
                              handleAddSingleApointmentToShare(appointment.Id);
                              handleOpenShareModal(e, appointment);
                            }}
                          >
                            <img src={Share} alt='Share' />
                          </button>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="actions">
                    {(appointment.AppointmentFileUrl) && (
                      <a href={appointment.AppointmentFileUrl} target='_blank' rel='noreferrer'>
                        <img src={Attachment} alt='Attachment' />
                      </a>
                    )}

                    <a href={`/edit/${appointment.Id}`}>
                      <img src={Edit} alt='Edit' />
                    </a>

                    {(!appointment.SharedAppointmentId) && (
                      <>
                        <button
                          type="button"
                          onClick={(e) => {
                            if (!appointment.AppointmentHasRecurrences) {
                              handleOpenModal(e, appointment);
                            }
                            else {
                              handleOpenModalOptions(e, appointment);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (!appointment.AppointmentHasRecurrences) {
                              handleOpenModal(e, appointment);
                            }
                            else {
                              handleOpenModalOptions(e, appointment);
                            }
                          }}
                        >
                          <img src={Trash} alt='Trash' />
                        </button>
                      </>
                    )}
                  </div>
                </Card>
              ))
            )}
          </CardsContainer>
          <div style={{ height: "75px" }}></div>

          <div className="fab-container">
            {
              (checkedAppointments.length > 0) && (
                <div
                  className="button-container"
                >
                  <Button
                    type='button'
                    className='button'
                    onClick={(e) => {
                      handleOpenShareModal(e);
                    }}
                    onKeyDown={(e) => {
                      handleOpenShareModal(e);
                    }}
                  >
                    Compartilhar
                  </Button>
                </div>
              )
            }

            <div
              className="button-container"
            >
              <div style={{ height: "20px" }}></div>
              <Button
                type='button'
                className='button secondary'
                onClick={(e) => {
                  window.open('/shared-management', '_self');
                }}
                onKeyDown={(e) => {
                  window.open('/shared-management', '_self');
                }}
              >
                Compartilhados comigo
              </Button>
            </div>
          </div>
        </>
      )}
    </Container >
  );
}