/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Container, Card, ErrorContainer, CardsContainer
} from './styles';
import Trash from '../../assets/icons/trash.svg';
import Edit from '../../assets/icons/edit.svg';
import Attachment from '../../assets/icons/attach-file.svg';
import Share from '../../assets/icons/share.svg';
import Calendar from '../../assets/icons/event.svg';
import Notifications from '../../assets/icons/notifications.svg';
import Info from '../../assets/icons/info.svg';
import Loader from '../Loader';
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { format } from 'date-fns';
import AppointmentsService from '../../services/AppointmentsService';
// import Button from '../Button';
import Button from '../Button';
import Modal from '../Modal';
import ModalOptions from '../ModalOptions';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
import PropTypes from 'prop-types';
import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'

export default function AllAppointmentsReport({ fetchOnlyActive, appointmentTypeId, startDate, endDate }) {
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  const [appointmentToShare, setAppointmentToShare] = useState(null);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  const listAppointments = useCallback(async () => {
    if (!sessionObject) {
      return;
    }

    try {
      setIsLoading(true);
      const appointmentsList = await new AppointmentsService(Config.API_BASE_URL).GetAllFiltered(
        {
          UserId: sessionObject.userId,
          FetchOnlyActive: fetchOnlyActive,
          AppointmentTypeId: appointmentTypeId,
          StartDate: startDate,
          EndDate: endDate,
        }
      );
      setAppointments(appointmentsList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject, fetchOnlyActive, appointmentTypeId, startDate, endDate]);

  async function handleReload() {
    await listAppointments();
  };

  useEffect(() => {
    listAppointments();
  }, [listAppointments]);

  async function handleDelete(e) {
    setIsModalOpen(false);
    setIsModalOptionsOpen(false);

    await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
      Id: appointmentToDelete.Id,
      OriginalAppointmentId: appointmentToDelete.OriginalAppointmentId ?? appointmentToDelete.Id,
    });

    await handleReload();
  }

  async function handleDeleteRecurrenceOnly(e) {
    setIsModalOpen(false);
    setIsModalOptionsOpen(false);

    await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
      Id: appointmentToDelete.Id,
    });

    await handleReload();
  }

  async function handleShareModal(e) {
    setIsShareModalOpen(false);
    setIsLoading(true);
    window.open(`/shared/${appointmentToShare.Id}`, '_self');
  }

  function handleOpenModal(e, appointment) {
    setAppointmentToDelete(appointment);
    setIsModalOpen(true);
  }

  function handleOpenShareModal(e, appointment) {
    setAppointmentToShare(appointment);
    setIsShareModalOpen(true);
  }

  function handleCloseShareModal(e, appointment) {
    setIsShareModalOpen(false);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  function handleOpenModalOptions(e, appointment) {
    setAppointmentToDelete(appointment);
    setIsModalOptionsOpen(true);
  }

  function handleCloseModalOptions() {
    setIsModalOptionsOpen(false);
  }

  return (
    <Container>
      <Loader isLoading={isLoading} />

      <Modal
        title='Compartilhar compromisso'
        body='Tem certeza que deseja compartilhar o compromisso?'
        isVisible={isShareModalOpen}
        // danger
        onConfirm={handleShareModal}
        onCancel={handleCloseShareModal}
      />

      <Modal
        title='Deletar compromisso'
        body='Deseja realmente deletar este compromisso? '
        isVisible={isModalOpen}
        danger
        onConfirm={handleDelete}
        onCancel={handleCloseModal}
      />

      <ModalOptions
        title='Deletar compromisso'
        body='Deseja realmente deletar este compromisso?
        Ele possui recorrências!'
        isVisible={isModalOptionsOpen}
        danger
        onCancel={handleCloseModalOptions}
        onOption1={handleDelete}
        onOption2={handleDeleteRecurrenceOnly}
        option1Text='Deletar todos'
        option2Text='Deletar esta ocorrência'
      />

      <div className="fab-container">
        <div
          className="button-container iconbutton"
        // onClick={handleOptions}
        >
          {/* <span className='icon'>
            <img src={PlusSign} alt='Plus sign' className={`${plusSignClass}`} />
          </span> */}
          <Button
            type='button'
            className='button'
            // onClick={handleOptions}
            // onKeyDown={handleOptions}
            onClick={(e) => { window.open("/new", "_self"); }}
          >
            Novo compromisso
          </Button>

          {/* {isOptionsVisible && (
            <ul className="options">
              <li>
                <a href="/new/4">
                  <span className="btn-label">
                    Documento com vencimento
                  </span>
                </a>
              </li>

              <li>
                <a href="/new/5">
                  <span className="btn-label">
                    Documento com pagamento
                  </span>
                </a>
              </li>

              <li>
                <a href="/new/6">
                  <span className="btn-label">
                    Agendamento geral
                  </span>
                </a>
              </li>
            </ul>
          )} */}
        </div>
      </div>

      {
        hasError && (
          <ErrorContainer>
            <span>
              Ocorreu um erro ao retornar os compromissos
            </span>
            <Button
              type="button"
              secodary
              onClick={(e) => { handleReload(); }}
            >
              Tentar novamente
            </Button>
          </ErrorContainer>
        )
      }

      {
        !hasError && (
          <>
            <CardsContainer>
              {appointments && (
                appointments.map((appointment) => (

                  <Card
                    key={`id-${appointment.Id}`}
                  >
                    <div className="info">
                      <div className="text">
                        {/* <div className="appointment-name">
                      <strong>{appointment.AppointmentCategoryName}</strong>
                    </div> */}

                        {appointment.Name && (
                          <div className="span type">
                            <b>{appointment.Name}</b>
                          </div>
                        )}

                        <div className="span type">
                          {appointment.AppointmentTypeName}
                        </div>

                        <div className="span">
                          {/* <FontAwesomeIcon icon={faCalendarDay} /> */}
                          <img src={Calendar} alt='date' />
                          {format(new Date(appointment.AppointmentDate), 'dd/MM/yyyy')}

                          {appointment.AppointmentTime && (
                            ` - ${appointment.AppointmentTime}`
                          )}
                        </div>

                        {(appointment.ReminderDates && appointment.ReminderDates[0]) && (
                          <div className="span">
                            {
                              appointment.ReminderDates[0] && (
                                <>
                                  {/* <FontAwesomeIcon icon={faBell} /> */}
                                  <img src={Calendar} alt='date' />
                                  {format(new Date(appointment.ReminderDates[0]), 'dd/MM/yyyy')}
                                </>
                              )
                            }

                            {
                              appointment.ReminderDates[1] && (
                                <>
                                  , {format(new Date(appointment.ReminderDates[1]), 'dd/MM/yyyy')}
                                </>
                              )
                            }

                            {
                              appointment.ReminderDates[2] && (
                                <>
                                  &nbsp;e {format(new Date(appointment.ReminderDates[2]), 'dd/MM/yyyy')}
                                </>
                              )
                            }

                          </div>
                        )}

                        {/* {
                      (appointment.DocumentValue !== 0) && (appointment.DocumentValue) && (
                        <>
                          <div className="span">
                            {appointment.DocumentValuestring}
                          </div>
                        </>
                      )
                    } */}

                        {appointment.Observations && (
                          <div className="span">
                            <img src={Info} alt='Info' />
                            {appointment.Observations}
                          </div>
                        )}

                        {appointment.AppointmentHasRecurrences && (
                          <div className="span">
                            <img src={Notifications} alt='Loop' />
                            Recorrente
                          </div>
                        )}

                      </div>
                      <div className="share">
                        {(!appointment.SharedAppointmentId) && (
                          <>
                            <button
                              type="button"
                              onClick={(e) => {
                                handleOpenShareModal(e, appointment);
                              }}
                            >
                              <img src={Share} alt='Share' />
                            </button>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="actions">
                      {(appointment.AppointmentFileUrl) && (
                        <a href={appointment.AppointmentFileUrl} target='_blank' rel='noreferrer'>
                          <img src={Attachment} alt='Attachment' />
                        </a>
                      )}

                      <a href={`/edit/${appointment.Id}`}>
                        <img src={Edit} alt='Edit' />
                      </a>

                      {(!appointment.SharedAppointmentId) && (
                        <>
                          <button
                            type="button"
                            onClick={(e) => {
                              // handleDelete(e, appointment.Id)
                              if (!appointment.AppointmentHasRecurrences) {
                                handleOpenModal(e, appointment);
                              }
                              else {
                                handleOpenModalOptions(e, appointment);
                              }
                            }}
                          >
                            <img src={Trash} alt='Trash' />
                          </button>
                        </>
                      )}
                    </div>
                  </Card>
                ))
              )}
            </CardsContainer>

            {(!appointments || appointments.length === 0) && (
              <div className='info-page'>
                <div className="image-header">
                  <img src={CalendarImage} alt='telembro' />
                </div>
                <div className="text-header">
                  Aqui você cadastra todos os seus compromissos de forma fácil e rápida.
                </div>
              </div>
            )}
          </>
        )
      }
    </Container >
  );
}

AllAppointmentsReport.propTypes = {
  fetchOnlyActive: PropTypes.bool.isRequired,
  appointmentTypeId: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

// Appointments.defaultProps = {
//   error: null,
// }