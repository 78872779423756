// import delay from '../../utils/delay';
import axios from 'axios';

class HttpClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  async get(path) {

    // await delay(500);
    const response = await fetch(`${this.baseUrl}${path}`);

    let body = null;
    const contentType = response.headers.get('Content-Type');
    if (contentType.includes('application/json')) {
      body = await response.json();
    }

    if (response.ok) {
      return body;
    }

    throw new Error(
      body?.error ||
      `${response.status} - ${response.statusText}`
    );
  }

  async post(path, model) {

    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    var url = `${this.baseUrl}${path}`;

    console.log(url);
    let response;

    try {
      response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(model),
        headers,
      });
    }
    catch (err) {
      console.log(err.message);
    }


    let body = null;
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      body = await response.json();
    }

    if (response.ok) {
      if (body) {
        return body;
      }
      else {
        return true;
      }
    }

    throw new Error(
      `${response.status} - ${response.statusText}`
    );
  }

  async postMultipart(path, model) {

    const headers = new Headers({
      'Content-Type': 'multipart/form-data'
    });

    var url = `${this.baseUrl}${path}`;

    console.log(url);
    let response;

    try {
      response = await fetch(url, {
        method: 'POST',
        // body: JSON.stringify(model),
        body: model,
        headers,
      });
    }
    catch (err) {
      console.log(err.message);
    }


    let body = null;
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      body = await response.json();
    }

    if (response.ok) {
      if (body) {
        return body;
      }
      else {
        return true;
      }
    }

    throw new Error(
      `${response.status} - ${response.statusText}`
    );
  }

  async postFormData(path, model) {

    // const headers = new Headers({
    //   // 'Content-Type': 'multipart/form-data',
    //   'Accept': 'application/json',
    // });

    const response = await fetch(`${this.baseUrl}${path}`, {
      method: 'POST',
      body: model,
      // headers,
    });

    if (response.ok) {
      return true;
    }

    throw new Error(
      `${response.status} - ${response.statusText}`
    );
  }

  async postFormDataAxios(path, model) {
    try {
      const response =
        // await axios.post(`${this.baseUrl}${path}`, model, {
        //   headers: {
        //     // "Content-Type": "multipart/form-data",
        //     'Accept': 'application/json',
        //   },
        // });
      await axios.post(`${this.baseUrl}${path}`, model);

      if (response.status === 200) {
        return response.data;
      }

      throw new Error(
        `${response.status} - ${response.statusText}`
      );
    }
    catch (ex) {
      console.log(ex);
    }
  }
}

export default HttpClient;