import SharedAppointmentsManagement from "../../components/SharedAppointmentsManagement"
import { useParams } from 'react-router-dom';

export default function SharedManagementSingle() {

  const {
    id,
  } = useParams();

  return (
    <SharedAppointmentsManagement
      appointmentId={id}
    />
  )
};