import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import {
  Overlay,
} from './styles';
import Button from '../Button';
// import Input from '../Input';
import InputAuto from '../InputAuto';
import isEmailValid from '../../utils/isEmailValid';
import CloseModalButton from '../../assets/icons/close-modal.svg'
import SharedAppointmentsService from '../../services/SharedAppointmentsService';
import Config from '../../config/environments/local';
import { localGet } from "../../lib/session";
import {
  useState,
  useEffect,
} from 'react';

export default function ModalShareEmail({ title, danger, onConfirm, onCancel, isVisible, emailValue, onChangeEmail }) {
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [sharedEmails, setSharedEmails] = useState([]);
  const [items, setItems] = useState([]);
  const [value, setValue] = useState("");
  // const [error, setError] = useState(null);


  useEffect(() => {
    async function getSharedWithEmails(e) {

      const getSharedWithEmails = await new SharedAppointmentsService(Config.API_BASE_URL).getSharedWithEmails(sessionObject.userId);

      // console.log(getSharedWithEmails);

      if (getSharedWithEmails.Error) {
        alert(getSharedWithEmails.Error.Message);
        console.log('Ocorreu um erro ao recuperar e-mails');
      }
      else {
        // console.log(getSharedWithEmails.Result);
        setSharedEmails(getSharedWithEmails.Result);
      }
    }

    getSharedWithEmails();
  }, [sessionObject.userId]);

  function handleCancel() {
    onCancel();
  }

  function onChange(evt) {
    setValue(evt.target.value);
    // setError(null);

    evt.preventDefault();

    if (evt.target.value && isEmailValid(evt.target.value)) {
      if (!isInList(evt.target.value)) {
        setItems([...items, evt.target.value]);
        onChangeEmail([...items, evt.target.value]);
        setValue("");
      }
    }
  }

  function handleDelete(item) {
    setItems(items.filter(i => i !== item));
    onChangeEmail(items.filter(i => i !== item));
  };

  function isButtonEnabled() {
    if (items.length > 0) {
      return true;
    }
    else {
      return false;
    }
  };

  function isInList(email) {
    return items.includes(email);
  }

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Overlay>
        <div danger={danger}
          className='modal-container'
        >
          <div className='modal-title'>
            <div className='text'>{title}</div>
            <div className='close-button'>
              <button
                onClick={handleCancel}
              >
                <img src={CloseModalButton} alt='telembro' />
              </button>
            </div>
          </div>
          <div className='modal-body'>
            {/* <Input
              autoFocus
              value={emailValue}
              onChange={onChangeEmail}
            /> */}
            {items.map(item => (
              <div className="tag-item" key={item}>
                {item}
                <button
                  type="button"
                  className="button"
                  onClick={() => handleDelete(item)}
                >
                  &times;
                </button>
              </div>
            ))}

            <InputAuto
              autoFocus
              value={value}
              // value={value}  
              // onChange={onChangeEmail}
              onChange={onChange}
              // label="languages"
              // pholder="Keyword..."
              data={sharedEmails}
            // onSelect={onKeyDown}
            // onSelect={(evt) => {
            //   onKeyDown(evt);
            // }}
            // // changeOnSelect={onKeyDown}
            // changeOnSelect={(evt) => {
            //   onKeyDown(evt);
            // }}
            // onSelected={getSelectedVal}
            />
            <div
              style={{
                fontStyle: 'italic',
                fontSize: 10,
              }}
            >
              Pressione "Enter" para adicionar um e-mail após digitá-lo por completo.
            </div>
          </div>
          <div
            className='button-container'
          >
            <Button
              type='button'
              // danger={danger}
              onClick={onConfirm}
              // disabled={!isEmailValid(emailValue)}
              disabled={!isButtonEnabled()}
              className='button'
            >
              Confirmar
            </Button>
          </div>
        </div>
      </Overlay>
    </>,
    document.getElementById('modal-root')
  );
}

ModalShareEmail.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  isVisible: PropTypes.bool,
  danger: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  emailValue: PropTypes.array.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
}

ModalShareEmail.defaultProps = {
  danger: false,
  isVisible: false,
}