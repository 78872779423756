const defaultTheme = {
  colors: {
    background: '#F6F6F6',
    primary: {
      main: '#F15C05',
      light: '#fc9a4f',
      lighter: '#ffd8af',
      dark: '#b04e02',
    },
    gray: {
      900: '#222222',
      200: '#BCBCBC',
    },
    danger: {
      main: '#fc5050',
      light: '#F97171',
      dark: '#F63131',
    }
  }
}

// https://smart-swatch.netlify.app/

export default defaultTheme;