import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'
// import ShieldI from '../../assets/icons/shield_i.svg'
import ShieldPerson from '../../assets/icons/shield_person.svg'
import Pdf from '../../assets/TELEMBRO_TERMOS_DE_USO_E_NAVEGACAO_E_POLITICA_DE_PRIVACIDADE.pdf'
export default function About() {
  return (
    <>
      <div className='info-page'>
        <div className="image-header">
          <img src={CalendarImage} alt='telembro' />
        </div>
        <div className="text-header">
          Explicaremos de forma simples e clara os usos que fazemos das suas informações, como e porque processamos seus dados.
        </div>

        <div className="info-card">
          <div className="info-card-header">
            <div className="image">
              <img src={ShieldPerson} alt='telembro' />
            </div>
            <div className="title">
              Termos de uso e navegação e Política de privacidade - TeLembro®
            </div>
          </div>
          <div className="info-card-content">
            O TeLembro é uma empresa de tecnologia e oferece serviços relacionados ao bem estar e agilidade para organizar seus compromissos.
            <br />
            <br />
            Explicaremos aqui o que fazemos com os seus dados.
            <br />
            <br />
          </div>
          <div className="info-card-link">
            <a rel='noreferrer' target='_blank' href={Pdf}>Clique e acesse o documento</a>
          </div>
          <div className="info-card-footer">
            Atualizado em julho de 2023
          </div>
        </div>

        {/* <div className="info-card">
          <div className="info-card-header">
            <div className="image">
              <img src={ShieldI} alt='telembro' />
            </div>
            <div className="title">
              Sua privacidade, seus direitos e o nosso compromisso
            </div>
          </div>
          <div className="info-card-content">
            Usamos seus dados para criar ferramentas que facilitam o seu dia a dia. Explicaremos aqui o que fazemos com os seus dados.
          </div>
          <div className="info-card-link">
            <a href="/privacy">Leia sobre...</a>
          </div>
          <div className="info-card-footer">
            Atualizado em julho de 2023
          </div>
        </div>

        <div className="info-card">
          <div className="info-card-header">
            <div className="image">
              <img src={ShieldPerson} alt='telembro' />
            </div>
            <div className="title">
              Termos e condições gerais de uso do aplicativo
            </div>
          </div>
          <div className="info-card-content">
            O TeLembro é uma empresa de tecnologia e oferece serviços relacionados ao bem estar e agilidade para organizar seus compromissos.
          </div>
          <div className="info-card-link">
            <a href="/terms-and-conditions">Leia sobre...</a>
          </div>
          <div className="info-card-footer">
            Atualizado em julho de 2023
          </div>
        </div> */}

        <div
          className="button-container"
        >
          <button
            type='button'
            className='button secondary'
            onClick={() => window.open('/', '_self')}
            onKeyDown={() => window.open('/', '_self')}
          >
            Voltar
          </button>
        </div>
      </div>
    </>
  )
};