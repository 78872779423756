// import { getDate } from "date-fns";
import Appointments from "../../components/Appointments";
// import CalendarVision from "../../components/CalendarVision";
import { useParams } from 'react-router-dom';
import { localGet } from "../../lib/session";
import Config from "../../config/environments/local";
import { useState } from "react";

export default function Home() {
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const { selectedDate } = useParams();

  if ((!sessionObject) || (!sessionObject.userEmail))
    window.open('/login', '_self');

  return (
    <>
      <Appointments
        dateSelected={selectedDate}
        // fetchOnlyActive={(!selectedDate)}
        fetchOnlyActive={false}
        calendarVisible={true}
        showToggler={true}
      />
      {/* <CalendarVision
        dateSelected={selectedDate}
        isVisible={true}
      /> */}
    </>
  )
};