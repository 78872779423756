import styled from "styled-components";

export const CheckboxAllContainer = styled.div`
  margin: auto;
  margin-right: 8px;
  margin-bottom: 24px;

  label{
    margin: auto;

    input{
      cursor: pointer;
      vertical-align: middle;
      margin: auto;
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  }
`;

export const CardsContainer = styled.div`
  /* margin-bottom: 72px; */
`;

export const CardAndCheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 72px;
`;

export const CheckboxContainer = styled.div`
  margin: auto;

  label{
    margin: auto;

    input{
      cursor: pointer;
      vertical-align: middle;
      margin: auto;
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  }
`;

export const Card = styled.div`
  background: #FFF;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
  padding-top: 13px;
  border-radius: 4px;
  /* display: grid;
  align-items: flex-start;
  justify-content: space-between; */

  & + &{
    margin-top: 16px;
  }
  
  button{
    background: transparent;
    border: none;
    /* margin-left: 8px; */
    margin-right: 8px;
    margin-left: 8px;
    cursor: pointer;
  }

  .info {
    padding-left: 13px;
    padding-right: 13px;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;

    .share{

    }

    .text{
      width: 100%;
      padding-bottom: 12px;

      .appointment-name {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        font-size: 16px;
        width: 245px;
        
        strong{
          inline-size: 245px; 
          overflow-wrap: break-word;
          height: auto;
        }

        small {
          /* background: ${({ theme }) => theme.colors.primary.lighter};
          color: ${({ theme }) => theme.colors.primary.main}; */
          /* text-transform: uppercase; */
          padding: 4px;
          border-radius: 4px;
          margin-left: 8px;
        }
      }

      .span{
        color: var(--telembro-preto-l, #636668);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
        min-height: 17px;
        margin-bottom: 6px;

        &.type{
          color: var(--telembro-azul-d, #6095A0);
          height: 26px;
        }

        img{
          margin-right: 5px;
        }
      }
    }
  }

  .shared-users-container{ 
    padding: 15px;
    border-top: 1px solid #E8E8E8;;

    .title{
      color: var(--telembro-azul-d, #6095A0);
      height: 26px;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;
      margin-bottom: 10px;
    }

    .shared-users-cards{

      .shared-user-card{
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: space-between;


        .name{
          color: var(--telembro-cinza-2, #999);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.28px;
        }

        img{
          width: 23px;
        }        
      }
    }
  }
`;

export const ListContainer = styled.div`
  margin-top: 24px;

  header{
    margin-bottom: 8px;

    .sort-button{
      background: transparent;
      border: none;
      display: flex;
      align-items: center;

      span {
        margin-right: 8px;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.primary.main};

        img{
          height: 8px;
        }
      }
    }
  }

  justify-content: left;
  display: flex;
  flex-direction: column;

  img{
    height: 74px;
  }
`;

export const Container = styled.div`
  margin-top: 32px;
  position: relative;

  .fab-container{
    /* display:flex;
    justify-content:flex-end; */
    position:fixed;
    bottom: 30px;
    left: 0;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    /* max-width: 400px; */
    /* left: 50%; */
    /* transform: translate(-50%, 0); */
    /* background: black; */
    /* padding-right: 24px; */
    text-align: center;

    .iconbutton{
      text-align: center;
      margin-bottom: 19px;

      .button-container{
        margin: auto;
        
        button{
          background: var(--telembro-laranja, #F25C05);
          color: #FFF;
          box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
          height: 52px;
          border: 2px solid #FFF;
          border-radius: 10px !important;
          outline: none;
          padding: 0 16px;
          font-size: 16px;
          transition: background all 0.2s ease-in;
          cursor: pointer;
        }
      }
    }
  }
`;

export const ErrorContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222;

  strong{
    color: ${({ theme }) => theme.colors.danger.main};
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray[100]};
  padding-bottom: 16px;

  strong{
    color: #222;
    font-size: 16px;
  }

  a{
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: none;
    font-weight: bold;
    padding: 8px 16px;
    border: 2px solid ${({ theme }) => theme.colors.primary.main};
    border-radius: 4px;
    transition: all 0.2s ease-in;

    &:hover{
      background: ${({ theme }) => theme.colors.primary.light};
      border: 2px solid ${({ theme }) => theme.colors.primary.light};
      color: #FFF;
    }

  }
`;