const Config = {

  ENVIRONMENT: "prd",
  // ENVIRONMENT: "hml",
  TELEMBRO_VERSION: "7.0.10",

  // //production
  API_BASE_URL: 'https://telembro.service.kroonar.com/api',
  ONESIGNAL_APPID: 'b604d823-5542-4afe-80e9-328673b8b557',

  // //homologation
  // // API_BASE_URL: 'https://homol-telembro.kroonar.com/api',
  // // ONESIGNAL_APPID: 'e2602481-f41d-47fe-9957-7fbcff0aeae9',

  // //local
  // API_BASE_URL: 'http://localhost:56320/api',
  // KROONAR_PRINT_URL: 'https://localhost:44309/Print/TeLembro',

  // //kroonar print
  // KROONAR_PRINT_URL: 'https://print.kroonar.com/Print/TeLembro',
  KROONAR_PRINT_URL: 'https://print.telembroapp.com.br/Print/TeLembro',

  // FILE_UPLOAD_API_BASE_URL: 'https://telembro-fileupload.kroonar.com/api',
  // FILE_UPLOAD_API_BASE_URL: 'http://localhost:56320/api',

  TERMS_KEY: 'telembro_termsandconditions_session',
  APPVERSION_KEY: 'telembro_appversion_session',
  SESSION_KEY: 'telembro_session',
  // EXPIRATION_AUTH: process.env.MAX_IDLE_TIME || 2.592e+9,
  // EXPIRATION_AUTH: 100000000,
  // EXPIRATION_AUTH: 604800000, //QUANTIDADE EM MILISEGUNDOS = 7 DIAS
  EXPIRATION_AUTH: (1000 * 60 * 60 * 24 * 7), //QUANTIDADE EM MILISEGUNDOS = 7 DIAS
  DEFAULT_USER_OBJECT: {
    userId: 1,
    userName: 'Márcio Alvim',
    userEmail: 'telembro@gmail.com',
    password: '123456',
    accessProfileId: 2,
  }
};

export default Config;