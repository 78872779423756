import styled from "styled-components";

export const Overlay = styled.div`
  background: rgba(0,0,0,0.6);
  /* backdrop-filter: blur(5px); */
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const Container = styled.div`
  background: #FFF;
  padding: 24px;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
  max-width: 400px;
  width: 100%;
  z-index: 999;
  position: fixed;
  bottom: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  h1{
    font-size: 22px;
    color: ${({ theme, danger }) => (
      danger ? theme.colors.danger.main :
      theme.colors.gray[900]
    ) };
  }

  p{
    margin-top: 8px;
  }
`;

export const Footer = styled.div`
  margin-top: 32px;
  display: grid;
  align-items: center;
  justify-content: flex-end;
`;