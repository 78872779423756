import { localGet, localRemove } from "../../lib/session";
import { useState, useEffect } from "react";
import Config from "../../config/environments/local";
import MenuButton from '../../assets/icons/menu/menu.svg'
import BackButton from '../../assets/icons/arrow-back.svg'
import FilterButton from '../../assets/icons/menu/filter.svg'
import InfoButton from '../../assets/icons/menu/info_FILL0_wght400_GRAD0_opsz48 1.svg'
import SideBar from '../SideBar';
// import Splash from '../Splash';
import Modal from "../Modal";
import ModalCancelUserRegistration from "../ModalCancelUserRegistration";
import ModalTermsAndConditions from '../ModalTermsAndConditions';
import ModalAppVersion from '../ModalAppVersion';
import Loader from '../Loader';
import UsersService from '../../services/UsersService';

export default function Header() {
  const [sessionObject, setSessionObject] = useState(localGet(Config.SESSION_KEY));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [menuText, setMenuText] = useState('Meus compromissos');
  const [isInfoIconVisible, setIsInfoIconVisible] = useState(true);
  const [isBackIconVisible, setIsBackIconVisible] = useState(false);
  const [backAction, setBackAction] = useState('/');
  // const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] = useState(true);
  const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] = useState(false);
  const [termsObject] = useState(localGet(Config.TERMS_KEY));
  const [isAppVersionModalOpen, setIsSppVersionModalOpen] = useState(false);
  const [appVersionObject] = useState(localGet(Config.APPVERSION_KEY));
  const [isCancelUserRegistrationModalOpen, setIsCancelUserRegistrationModalOpen] = useState(false);

  function handleOpenModalCancelUserRegistration() {
    setIsCancelUserRegistrationModalOpen(true);
  }

  function handleCloseTermsAndConditions() {
    setIsTermsAndConditionsModalOpen(false);
  }

  function handleCloseAppversionModal() {
    setIsSppVersionModalOpen(false);
  }

  useEffect(() => {

    if (termsObject) {
      setIsTermsAndConditionsModalOpen(false);
    } else {
      setIsTermsAndConditionsModalOpen(true);
    }

    if (appVersionObject) {
      setIsSppVersionModalOpen(false);
    } else {
      setIsSppVersionModalOpen(true);
    }

    if (!sessionObject) {
      const location = window.location.pathname;

      if (
        (!location.includes('login')) &&
        (!location.includes('users')) &&
        (!location.includes('forgot-password')) &&
        (!location.includes('about')) &&
        (!location.includes('reset-password'))
      ) {
        window.open('/login', '_self');
      }

      if (
        (location.includes('users/all'))
      ) {
        window.open('/login', '_self');
      }
    }
    else {
      setIsHeaderVisible(true);

      const location = window.location.pathname;

      if (location.includes('about')) {
        setMenuText('Sobre o app');
        setIsBackIconVisible(true);
        setIsInfoIconVisible(true);
      }
      else if (location.includes('privacy')) {
        setMenuText('Privacidade');
        setIsBackIconVisible(true);
        setBackAction('/about');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('terms-and-conditions')) {
        setMenuText('Termos e Condições');
        setIsBackIconVisible(true);
        setBackAction('/about');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('talk-to-us')) {
        setMenuText('Fale Conosco');
        setIsBackIconVisible(true);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('shared-messages')) {
        setMenuText('Mensagens');
        setIsBackIconVisible(true);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('shared-management')) {
        setMenuText('Compartilhados comigo');
        setIsBackIconVisible(true);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('shared/')) {
        setMenuText('Compartilhados');
        setIsBackIconVisible(true);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('shared')) {
        setMenuText('Compartilhar');
        setIsBackIconVisible(true);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('forgot-password')) {
        setMenuText('Recuperação de senha');
        setIsBackIconVisible(true);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('reset-password')) {
        setMenuText('Recuperação de senha');
        setIsBackIconVisible(true);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('change-password')) {
        setMenuText('Trocar senha');
        setIsBackIconVisible(true);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('appointment-types/new')) {
        setMenuText('Novo tipo');
        setIsBackIconVisible(true);
        setBackAction('/appointment-types');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('appointment-types/edit')) {
        setMenuText('Editar tipo');
        setIsBackIconVisible(true);
        setBackAction('/appointment-types');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('appointment-types')) {
        setMenuText('Gerenciar tipos');
        setIsBackIconVisible(true);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('app-versions')) {
        setMenuText('Versões');
        setIsBackIconVisible(false);
        setBackAction('/app-versions/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('app-versions/new')) {
        setMenuText('Nova versão');
        setIsBackIconVisible(true);
        setBackAction('/app-versions/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('configurations')) {
        setMenuText('Configurações');
        setIsBackIconVisible(false);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('timezones')) {
        setMenuText('Configuração de fuso horário');
        setIsBackIconVisible(false);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('new')) {
        setMenuText('Novo compromisso');
        setIsBackIconVisible(false);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('edit')) {
        setMenuText('Editar compromisso');
        setIsBackIconVisible(true);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('users/all')) {

        if (sessionObject.userEmail !== 'telembroapp@gmail.com') {
          window.open('/', '_self');
          return false;
        }

        setMenuText('Usuários');
        setIsBackIconVisible(true);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }
      else if (location.includes('all')) {
        setMenuText('Compromissos');
        setIsBackIconVisible(true);
        setBackAction('/');
        setIsInfoIconVisible(true);
      }

      else {
        setIsInfoIconVisible(false);
      }
    }
  }, [sessionObject, termsObject, appVersionObject])

  function handleLogout() {
    localRemove(Config.SESSION_KEY);
    setSessionObject(null);
    window.open('/', '_self');
  }

  async function handleCancelUserRegistration() {
    try {
      setIsLoading(true);

      const deleteResponse = await new UsersService(Config.API_BASE_URL).Delete(
        {
          Id: sessionObject.userId,
        }
      );

      if (deleteResponse.Result) {
        localRemove(Config.SESSION_KEY);
        setSessionObject(null);
        window.open('/', '_self');
      }

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <>
      {(isHeaderVisible && (
        <>
          <Modal
            title='Dica'
            body='Esta é uma dica para te ajudar no entendimento da plataforma.'
            tip
            isVisible={isModalOpen}
            onConfirm={() => setIsModalOpen(false)}
            onCancel={() => setIsModalOpen(false)}
          />
          <ModalCancelUserRegistration
            title='Cancelamento de cadastro'
            body='Ao clicar em aceitar, você declara estar ciente de que
              não mais terá acesso ao aplicativo Telembro, e de que
              os compromissos que foram compartilhados com
              você serão mantidos, mas você não terá mais acesso
              a eles.<br /><br />
              Também, não enviaremos mais e-mails referentes a
              compromissos para você.<br /><br />
              Conforme nossos termos de uso e <a target="_blank" href="https://telembroapp.com/static/media/TELEMBRO_TERMOS_DE_USO_E_NAVEGACAO_E_POLITICA_DE_PRIVACIDADE.646269393241ab048041.pdf">política de
              privacidade</a>, seus dados de usuário (inclusive login e
              senha), assim como todos os compromissos que vocè
              criou serão conservados por nós pelo prazo
              necessário para cumprimento de obrigações legais e
              após, armazenaremos os seus dados pessoais em um
              formato agregado e anonimizado. As informações que
              não permitem a identificação pessoal podem ser
              conservadas indefinidamente para fins de análise.<br /><br />
              Esta ação é irreversivel e, para ter acesso novamente
              ao TeLembro, você terá que criar um novo login.<br /><br />
              Deseja prosseguir?'
            isVisible={isCancelUserRegistrationModalOpen}
            onConfirm={handleCancelUserRegistration}
            onCancel={(e) => {
              setIsCancelUserRegistrationModalOpen(false);
            }}
          />
          <Loader isLoading={isLoading} />
          <SideBar
            isVisible={isMenuOpen}
            onClose={toggleMenu}
            onLogOut={handleLogout}
            showUserLink={sessionObject.userEmail === 'telembroapp@gmail.com'}
            onOpenModalCancelUserRegistration={handleOpenModalCancelUserRegistration}
          />
          {/* <Splash /> */}
          <div className="menu-bar-container">
            <div className="menu-bar">
              {(isBackIconVisible) ?
                (
                  <div className="menu-button-container back">
                    <button
                      onClick={() => window.open(backAction, "_self")}
                    >
                      <img src={BackButton} alt='voltar' />
                    </button>
                  </div>
                ) :
                (
                  <div className="menu-button-container">
                    <button
                      onClick={() => toggleMenu()}
                    >
                      <img src={MenuButton} alt='menu' />
                    </button>
                  </div>

                )}
              <div className="page-title">
                {menuText}
              </div>
              <div className="options-button-container">
                {(isInfoIconVisible) ?
                  (
                    <button
                      onClick={() => setIsModalOpen(true)}
                    >
                      <img src={InfoButton} alt='info' />
                    </button>
                  ) :
                  (
                    <button
                      onClick={() => window.open("/all", "_self")}
                    >
                      <img src={FilterButton} alt='filtrar' />
                    </button>
                  )}
              </div>
            </div>
          </div>
        </>
      ))}

      {
        ((sessionObject) && (
          <>
            <ModalTermsAndConditions
              title='Termos de uso e privacidade'
              // title='TERMOS DE USO E NAVEGAÇÃO E POLÍTICA DE PRIVACIDADE - TeLembro®'
              isVisible={isTermsAndConditionsModalOpen}
              onCancel={handleCloseTermsAndConditions}
            />

            <ModalAppVersion
              title='Versão do app'
              // title='TERMOS DE USO E NAVEGAÇÃO E POLÍTICA DE PRIVACIDADE - TeLembro®'
              isVisible={isAppVersionModalOpen}
              onCancel={handleCloseAppversionModal}
            />
          </>
        ))
      }
    </>
  );

}