import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import {
  Overlay,
  // Container, Footer
} from './styles';
import Button from '../Button';
import CloseModalButton from '../../assets/icons/close-modal.svg'

export default function ModalOptions({ title, body, danger, onCancel, onOption1, onOption2, option1Text, option2Text, isVisible }) {

  function handleCancel() {
    onCancel();
  }

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Overlay>
        <div danger={danger}
          className='modal-container'
        >
          <div className='modal-title'>
            <div className='text'>{title}</div>
            <div className='close-button'>
              <button
                onClick={handleCancel}
              >
                <img src={CloseModalButton} alt='telembro' />
              </button>
            </div>
          </div>
          <div className='modal-body'>
            {body}
          </div>

          <div
            className='button-container'
          >
            <Button
              type='button'
              danger={danger}
              onClick={onOption1}
              className='button'
            >
              {option1Text}
            </Button>
            <Button
              type='button'
              danger={danger}
              onClick={onOption2}
              className='button'
            >
              {option2Text}
            </Button>
          </div>
        </div>
      </Overlay>
    </>,
    document.getElementById('modal-root')
  );
}

ModalOptions.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  danger: PropTypes.bool,
  onOption1: PropTypes.func.isRequired,
  onOption2: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  option1Text: PropTypes.string.isRequired,
  option2Text: PropTypes.string.isRequired,
}

ModalOptions.defaultProps = {
  danger: false,
  isVisible: false,
}