import HttpClient from './utils/HttpClient';

class UsersService {
  constructor(baseUrl) {

    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async login(userData) {
    const urlPath = '/users/login';

    return this.httpClient.post(urlPath, userData);
  }

  async ResetPassword(userData) {
    const urlPath = '/users/reset-password';

    return this.httpClient.post(urlPath, userData);
  }

  async UpdatePassword(userData) {
    const urlPath = '/users/update-password';

    return this.httpClient.post(urlPath, userData);
  }

  async Create(userData) {
    const urlPath = '/users';

    return this.httpClient.post(urlPath, userData);
  }

  async Update(userData) {
    const urlPath = '/users/update';

    return this.httpClient.post(urlPath, userData);
  }

  async Delete(userData) {
    const urlPath = '/users/delete';

    return this.httpClient.post(urlPath, userData);
  }

  async ActivateUser(code) {
    const urlPath = '/users/activate-user';

    return this.httpClient.post(urlPath, code);
  }

  async GetUserByCode(code) {
    const urlPath = '/users/get-by-code';

    return this.httpClient.post(urlPath, code);
  }

  async GetById(userId) {
    const urlPath = `/users/get/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async GetAll() {
    const urlPath = `/users/get-all`;

    return this.httpClient.get(urlPath);
  }
}

export default UsersService;