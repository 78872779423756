import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
  }

  /* body{
    background: var(--telembro-branco-bgk, #F6F6F6);
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
  } */

  button {
    cursor: pointer;
  }
`;