import { useParams, useHistory } from 'react-router-dom';
import AppVersionForm from "../../components/AppVersionForm";
import AppVersionService from '../../services/AppVersionService';
import { useState, useEffect } from "react";
import Loader from '../../components/Loader';
import Config from '../../config/environments/local';
// import { localGet } from "../../lib/session";

export default function EditAppVersion() {

  const [appVersion, setAppVersion] = useState({});
  // const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const { history } = useHistory();

  useEffect(() => {
    async function getAppVersion() {
      try {
        setIsLoading(true);

        const response = await new AppVersionService(Config.API_BASE_URL).getById(id);

        setAppVersion(response.Result);
      } catch (error) {
        console.log('error ', error);
        history.push('/');
      } finally {
        setIsLoading(false);
      }
    }

    getAppVersion();
  }, [id, history]);

  async function handleSubmit(formData) {

    const response = await new AppVersionService(Config.API_BASE_URL).editAppVersion(appVersion.Id, formData.versionNumber, formData.versionDetails, false);

    return response;
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <AppVersionForm
        key={appVersion.Id}
        buttonLabel='Salvar alterações'
        onSubmit={handleSubmit}
        appVersion={appVersion}
      />
    </>
  )
};