import GlobalStyles from '../../assets/styles/global';
import { ThemeProvider } from "styled-components";
import defaultTheme from '../../assets/styles/themes/default';
import { Container } from './styles';
import Header from '../Header';
import Routes from '../../Routes';
import { BrowserRouter } from 'react-router-dom';
import OneSignal from 'react-onesignal';
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  localGet,
  localSet,
} from '../../lib/session';
import Config from '../../config/environments/local';
import TimezoneService from "../../services/TimezoneService";

function App() {
  const appId = Config.ONESIGNAL_APPID;
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [timezoneOffset] = useState((new Date().getTimezoneOffset() * -1));
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const UpdateUserTimezone = useCallback(async () => {
    try {
      // setIsLoading(true);
      var updateUserTimezoneResponse = await new TimezoneService(Config.API_BASE_URL).UpdateUserTimezone({
        UserId: sessionObject.userId,
        TimezoneString: timeZone,
      });

      if ((!updateUserTimezoneResponse.Error) && updateUserTimezoneResponse.Result.TimezoneId) {
        if (sessionObject !== updateUserTimezoneResponse.Result.TimezoneId) {
          sessionObject.timezoneId = updateUserTimezoneResponse.Result.TimezoneId;
          sessionObject.localTimezoneString = timeZone;
          sessionObject.TimezoneOffset = timezoneOffset;
          localSet(Config.SESSION_KEY, sessionObject, Config.EXPIRATION_AUTH);
        }
      }


    } catch (error) {
      console.log('error ', error);
    } finally {
      // setIsLoading(false);
    }
  }, [sessionObject, timeZone, timezoneOffset]);

  const UpdateUserTimezoneOffsetOnly = useCallback(async () => {
    try {
      // setIsLoading(true);
      var updateUserTimezoneResponse = await new TimezoneService(Config.API_BASE_URL).UpdateUserTimezone({
        UserId: sessionObject.userId,
        TimezoneString: timeZone,
        TimezoneOffset: timezoneOffset
      });

      if ((!updateUserTimezoneResponse.Error) && updateUserTimezoneResponse.Result.TimezoneId) {
        if (sessionObject !== updateUserTimezoneResponse.Result.TimezoneId) {
          sessionObject.timezoneId = updateUserTimezoneResponse.Result.TimezoneId;
          sessionObject.localTimezoneString = timeZone;
          sessionObject.TimezoneOffset = timezoneOffset;
          localSet(Config.SESSION_KEY, sessionObject, Config.EXPIRATION_AUTH);
        }
      }


    } catch (error) {
      console.log('error ', error);
    } finally {
      // setIsLoading(false);
    }
  }, [sessionObject, timeZone, timezoneOffset]);

  useEffect(() => {
    console.log(timeZone);

    if (timeZone && sessionObject && (sessionObject.localTimezoneString !== timeZone)) {
      UpdateUserTimezone();
    }
    else if (timeZone && sessionObject && (sessionObject.localTimezoneString === timeZone)) {
      if (sessionObject.timezoneOffset && (sessionObject.timezoneOffset !== timezoneOffset)) {
        UpdateUserTimezoneOffsetOnly();
      }
    }

    let initWasCalled = false;
    if (sessionObject) {
      if (!OneSignal.pendingInit && (!initWasCalled)) {
        initWasCalled = true;
        OneSignal.init({
          appId: appId,
          allowLocalhostAsSecureOrigin: true,
          persistNotification: true,
          autoResubscribe: true,
        }).then(() => {
          console.log('initialized');
          if (sessionObject.pushNotificationSubscriptionId) {
            OneSignal.login(sessionObject.pushNotificationSubscriptionId).then(() => {
              console.log('logged in');
              console.log(OneSignal.User.PushSubscription.token);
              console.log(OneSignal.User.PushSubscription.id);
              localSet('onesignalsubscriptionid', OneSignal.User.PushSubscription.id);
            });
          }
        });
      }

    }
  }, [UpdateUserTimezone, UpdateUserTimezoneOffsetOnly, sessionObject, timeZone, timezoneOffset, appId]);


  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyles />
        <Container>
          <Header />
          <Routes />
        </Container>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
