import { Container } from './styles';
import ArrowBack from '../../assets/icons/arrow-back.svg';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function PageHeader({ title, urlBack }) {

  const backUrl = urlBack ?? "/";

  return (
    <Container>
      <a href={backUrl}>
        <img src={ArrowBack} alt='back' />
        <span>Voltar</span>
      </a>
      <h1>{title}</h1>
    </Container>

  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
}