import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import {
  Overlay,
} from './styles';
import Button from '../Button';
// import Input from '../Input';
import CloseModalButton from '../../assets/icons/close-modal.svg'
import TermsAndConditionsService from '../../services/TermsAndConditionsService';
import Config from '../../config/environments/local';
import { localGet, localSet } from '../../lib/session';
import {
  useState,
  useEffect,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faCalendarDay,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';

export default function ModalTermsAndConditions({ title, danger, onCancel, isVisible }) {
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  // const [sharedEmails, setSharedEmails] = useState([]);
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  // const [items, setItems] = useState([]);
  // const [value, setValue] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);


  useEffect(() => {
    async function geTermsAndConditions(e) {

      const listTermsAndConditions = await new TermsAndConditionsService(Config.API_BASE_URL).listTermsAndConditions(sessionObject.userId);

      // console.log(getSharedWithEmails);

      if (listTermsAndConditions.Error) {
        alert(listTermsAndConditions.Error.Message);
        console.log('Ocorreu um erro ao recuperar termos e condições');
      }
      else {
        // console.log(getSharedWithEmails.Result);
        setTermsAndConditions(listTermsAndConditions.Result);
      }
    }

    geTermsAndConditions();
  }, [sessionObject.userId]);

  function handleCancel() {
    onCancel();
  }

  async function handleConfirm() {
    const acceptTermsAndConditions = await new TermsAndConditionsService(Config.API_BASE_URL).acceptTermsAndConditions(sessionObject.userId, termsAndConditions.Id);

    // console.log(getSharedWithEmails);

    if (acceptTermsAndConditions.Error) {
      alert(acceptTermsAndConditions.Error.Message);
      console.log('Ocorreu um erro ao aceitar os termos e condições');
    }
    else {
      // console.log(getSharedWithEmails.Result);
      // setTermsAndConditions(acceptTermsAndConditions.Result);
      localSet(Config.TERMS_KEY, true, Config.EXPIRATION_AUTH);
      onCancel();
    }
  }

  function EnableButton() {
    setIsButtonEnabled(true);
  }

  function handleScroll(event) {
    // onCancel();

    const target = event.target;
    if (target.scrollHeight - target.scrollTop <= (target.clientHeight + 500)) {
      // alert("scrolled");
      setIsButtonEnabled(true);
    }
  }

  if (!isVisible || (!termsAndConditions)) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Overlay>
        <div danger={danger}
          className='modal-container full'
        >
          <div className='modal-title'>
            <div className='text'>{title}</div>
            <div className='close-button'>
              <button
                onClick={handleCancel}
              >
                <img src={CloseModalButton} alt='telembro' />
              </button>
            </div>
          </div>
          <div className='modal-body full'>
            <a
              href={termsAndConditions.FileUrlPath}
              target='_blank'
              rel='noreferrer'
              onClick={EnableButton}
              className='button'
            >
              <FontAwesomeIcon icon={faDownload} />
              Baixar PDF
            </a>
            <div className='terms-content' dangerouslySetInnerHTML={{ __html: (termsAndConditions.TermsContent) }}
              onScroll={handleScroll}
            />
          </div>
          <div
            className='button-container'
          >
            <Button
              type='button'
              // danger={danger}
              // onClick={onConfirm}
              onClick={handleConfirm}
              // disabled={!isEmailValid(emailValue)}
              disabled={!isButtonEnabled}
              className='button'
            >
              Li e concordo com os termos
            </Button>
          </div>
        </div>
      </Overlay>
    </>,
    document.getElementById('modal-root')
  );
}

ModalTermsAndConditions.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  danger: PropTypes.bool,
  onCancel: PropTypes.func,
}

ModalTermsAndConditions.defaultProps = {
  danger: false,
  isVisible: false,
}