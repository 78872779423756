import { useState } from 'react';
import { Container } from './styles';
import useErrors from '../../hooks/useErrors';
import { Form, ButtonContainer } from './styles';
// import PageHeader from '../../components/PageHeader';
import FormGroup from '../../components/FormGroup';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Config from '../../config/environments/local';
import UsersService from '../../services/UsersService';
import Loader from '../../components/Loader';
import { localGet } from "../../lib/session";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
  // faDollarSign,
} from '@fortawesome/free-solid-svg-icons'
import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'

export default function ChangePassword() {

  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const isFormValid = (password && passwordAgain && (password === passwordAgain));
  const [isLoading, setIsLoading] = useState(false);
  const [isResetPaswordSuccess, setIsResetPaswordSuccess] = useState(false);
  const [serviceError, setServiceError] = useState('');
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const {
    // errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  function isPasswordValid(password) {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return regex.test(password);
  }

  function handleChangePassword(e) {
    setPassword(e.target.value);

    if (!e.target.value) {
      setError({ field: 'password', message: 'a senha é obrigatória' });
    } else {
      if (passwordAgain && (passwordAgain !== e.target.value)) {
        setError({ field: 'password', message: 'digite a mesma senha' });
        return;
      }
      else if (!isPasswordValid(e.target.value)) {
        setError({ field: 'password', message: 'a senha deve conter um mínimo de 8 letras, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula e pelo menos um número' });
      }
      else {
        removeError('password');
        removeError('passwordAgain');
      }
    }
  }

  function handleChangePasswordAgain(e) {
    setPasswordAgain(e.target.value);

    if (!e.target.value) {
      setError({ field: 'passwordAgain', message: 'digite a mesma senha novamente' });
    } else {

      if (password !== e.target.value) {
        setError({ field: 'passwordAgain', message: 'digite a mesma senha novamente' });
        return;
      }
      else if (!isPasswordValid(e.target.value)) {
        setError({ field: 'passwordAgain', message: 'a senha deve conter um mínimo de 8 letras, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula e pelo menos um número' });
      }
      else {
        removeError('passwordAgain');
      }
    }
  }

  // async function handleGoToLogin(e) {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   window.open('/login', '_self');
  // }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    const response = await new UsersService(Config.API_BASE_URL).UpdatePassword({
      UserId: sessionObject.userId,
      Password: password,
    });

    if (response.Error) {
      setError({ field: 'password', message: response.Error.Message });
      setServiceError(response.Error.Message);
    } else {
      setIsResetPaswordSuccess(true);
      removeError('password');
      removeError('passwordAgain');
    }

    setIsResetPaswordSuccess(true);
    removeError('password');
    removeError('passwordAgain');

    setIsLoading(false);

  }

  return (
    <>
      {(!isResetPaswordSuccess) ? (
        <>
          <div className="no-results-image-container">
            <div className='image'>
              <img src={CalendarImage} alt='logo' />
            </div>

            <div className='text'>
              Aqui você faz a troca de senha
              <br />
              com muita facilidade e
              <br /> segurança.
            </div>
          </div>
          <div style={{ height: '27px' }}></div>

          <Form onSubmit={handleSubmit} noValidate>
            <FormGroup
              error={getErrorMessageByFieldName('password')}
            >
              <div className='form-label'>Informe a nova senha</div>
              <div className="input-group-btn">
                <Input
                  type={passwordType}
                  value={password}
                  placeholder="Nova senha"
                  onChange={handleChangePassword}
                  error={getErrorMessageByFieldName('password')}
                />
                <button type="button" className="btn" onClick={togglePassword}>
                  {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </button>
              </div>
            </FormGroup>

            <FormGroup
              error={getErrorMessageByFieldName('passwordAgain')}
            >
              <div className='form-label'>Digite a sua senha novamente</div>
              <div className="input-group-btn">
                <Input
                  type={passwordType}
                  value={passwordAgain}
                  placeholder="Senha novamente"
                  onChange={handleChangePasswordAgain}
                  error={getErrorMessageByFieldName('passwordAgain')}
                />
                <button type="button" className="btn" onClick={togglePassword}>
                  {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </button>
              </div>
            </FormGroup>

            <Loader isLoading={isLoading} />

            <ButtonContainer>
              <Button
                type="button"
                disabled={!isFormValid}
                onClick={(e) => handleSubmit(e)}
              >
                Trocar senha
              </Button>
            </ButtonContainer>

            <ButtonContainer>
              <Button
                type="button"
                secondary
                className='secondary'
                onClick={(e) => window.open('/', '_self')}
              >
                Cancelar
              </Button>
            </ButtonContainer>
            <div style={{ height: '27px' }}></div>
          </Form >
        </>
      ) : (
        <Container>
          <div className="no-results-image-container">
            <div className='image'>
              <img src={CalendarImage} alt='logo' />
            </div>

            {(serviceError) ? (
              <div className='text'>
                {serviceError}
              </div>
            ) : (
              <div className='text'>
                Senha alterada com sucesso.
              </div>
            )}
          </div>

          <div style={{ height: '27px' }}></div>

          {(serviceError) && (
            <ButtonContainer>
              <Button
                type="button"
                onClick={(e) => setIsResetPaswordSuccess(false)}
              >
                Tentar novamente
              </Button>
            </ButtonContainer>
          )}
        </Container>
      )}


    </>
  )
};