import HttpClient from './utils/HttpClient';

class AppointmentTypesService {
  constructor(baseUrl) {
    
    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async Create(data) {
    const urlPath = '/appointmenttypes';

    return this.httpClient.post(urlPath, data);
  }

  async Delete(data) {
    const urlPath = '/appointmenttypes/delete';

    return this.httpClient.post(urlPath, data);
  }

  async Update(data) {
    const urlPath = '/appointmenttypes/update';

    return this.httpClient.post(urlPath, data);
  }

  async listAppointmentTypes() {
    const urlPath = '/appointmenttypes/get-all';

    return this.httpClient.get(urlPath);
  }

  async GetById(id) {
    const urlPath = `/appointmenttypes/get/${id}`;

    return this.httpClient.get(urlPath);
  }
  
  async listAppointmentTypesByUserId(userId) {
    const urlPath = `/appointmenttypes/get-by-user/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async listAppointmentTypesByUserIdOnly(userId) {
    const urlPath = `/appointmenttypes/get-by-user-only/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async listAppointmentTypesByCategoryId(appointmentCategoryId, userId) {
    const urlPath = `/appointmenttypes/get-by-category/${appointmentCategoryId}/users/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async addAppointmentType(appointmentType) {
    const urlPath = `/appointmenttypes/`;

    return this.httpClient.post(urlPath, appointmentType);
  }
}

export default AppointmentTypesService;