import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 32px;
  position: relative;
  /* max-height: 111px; */

  .counter{
      display: flex;
      justify-content: flex-end;
      color: rgba(170, 170, 170, 0.67);
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

  .small-select{
    width: 47%;
  }

  .link-button{
      background: transparent;
      border: none;
      margin-left: 8px;
      cursor: pointer;

      img{
        margin-top: -6px;
      }
  }
  /* & + &{
    margin-bottom: 32px;
  } */
  &.button-group{
    display: flex;
    justify-content: space-between;

    /* div + div {
      margin-right: 10px;
    } */
  }

  &.toggle{
    display: flex;
    /* align-items: flex-start;
    justify-content: space-between; */
    /* margin: auto; */
    
    .side-label{
      margin: auto;
      margin-left: 0;
      margin-right: 26px;

      color: var(--telembro-preto-l, #636668);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }

  .creatable-select{
    
    .css-13cymwt-control, .css-t3ipsp-control{
      min-height: 56px;
      padding-top: 10px;
      padding-left: 6px;
      outline: none;
      border: none;
      border-bottom: 1px solid #BBB;
      border-radius: 4px 4px 0px 0px;
      color: #636668 !important;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.36px;
    }

    .css-1nmdiq5-menu{
      z-index: 2;
    }
  }

  .form-label{
    position: absolute;
    top: 6px;
    left: 16px;
    color: #999;
    font-size: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;
    z-index: 1;

    &.counter{
      left: 105px;

      @media (max-width: 767px) {
        left: 83.5px;
      }
    }

    &.not-input{
      left: 0px;
    }
  }

  .anchor-container{
    text-align: center;
    padding-top: 14px;
    /* height: 40px; */
    a{
      font-size: 14px;
      color: #F25C05;
      text-decoration: none;
    }
  }

  &.small{
    max-width: 50%;
  }

  &.complex {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    max-width: 50%;

    .group {
      input{
        /* max-width: 250px; */
        /* max-width: 90%; */
      }
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.recurrencenit{
        input{
        /* max-width: 250px; */
          max-width: 100px !important;
        }
        display: flex !important;
        align-items: flex-start !important;
        justify-content: space-evenly !important;

        button{
          font-size: 24px !important;
        }
      }
    }
    
    button{
      background: transparent;
      border: none;
      margin-left: 8px;
      cursor: pointer;

      &.counter{
        background: ${({ theme }) => theme.colors.primary.main};
        color: #FFF;
        box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
        height: 52px;
        border: 2px solid #FFF;
        border-radius: 4px;
        outline: none;
        padding: 0 16px;
        font-size: 16px;
        transition: background all 0.2s ease-in;
        font-weight: bolder !important;
        cursor: pointer;

        /* &:hover{
          background: ${({ theme }) => theme.colors.primary.light};
        }

        &:active{
          background: ${({ theme }) => theme.colors.primary.dark};
        } */
      }
    }
  }

  &.complexcounter {
    .group {
      width: 64%;
      margin: 0;
      margin-right: 6px;
      display: flex;
      align-items: flex-start;
      text-align: left;

      input{
        max-width: 57px;
        text-align: center;
      }
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &.recurrencenit{
        input{
        /* max-width: 250px; */
          max-width: 100px !important;
        }
        

        button{
          font-size: 24px !important;
        }
      }
    }
    
    button{
      /* width:24%; */
      width:55px;
      background: transparent;
      color: #FFF;
      height: 52px;
      outline: none;
      /* padding: 0 16px; */
      padding: 12px 21px;
      font-size: 18px;
      transition: background all 0.2s ease-in;
      cursor: pointer;
      padding-left: 14px;
      border: none;

      &:not(.trash-button){
        background: #F6F6F6;
        border: 2px solid ${({ theme }) => theme.colors.primary.main};
        box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
      }

      color: var(--telembro-azul-d, #6095A0);
      text-align: center;
      /* TL-botao */
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border-radius: 10px;
      margin: auto;
      margin-top: 3px;

      /* &:hover{
        background: ${({ theme }) => theme.colors.primary.light};
      }

      &:active{
        background: ${({ theme }) => theme.colors.primary.dark};
      } */
    }
  }

  small{
    margin-top: 8px;
    font-size: 12px;
    display: block;
    color: ${({ theme }) => theme.colors.danger.main};
  }
`;