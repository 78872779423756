import { useParams, useHistory } from 'react-router-dom';
import PageHeader from "../../components/PageHeader";
import UserForm from "../../components/UserForm";
import UsersService from '../../services/UsersService';
import { useState, useEffect } from "react";
import Loader from '../../components/Loader';
import Config from '../../config/environments/local';

export default function EditUser() {

  const [user, setUser] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const { history } = useHistory();

  useEffect(() => {
    async function getUser() {
      try {
        setIsLoading(true);

        const response = await new UsersService(Config.API_BASE_URL).GetById(id);

        setUser(response.Result);
      } catch (error) {
        console.log('error ', error);
        history.push('/');
      } finally {
        setIsLoading(false);
      }
    }

    getUser();
  }, [id, history]);

  async function handleSubmit(formData) {
    
    await new UsersService(Config.API_BASE_URL).Update({
      Id: formData.Id,
      Name: formData.name,
      Email: formData.email,
      Password: formData.password,
    });

    window.open('/', '_self');
  }

  

  return (
    <>
      <Loader isLoading={isLoading} />
      <PageHeader
        title='Editar usuário'
      />
      <UserForm
        key={user.Id}
        buttonLabel='Salvar alterações'
        onSubmit={handleSubmit}
        user={user}
      />
    </>
  )
};