import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Shared from './pages/Shared';
import SharedManagement from './pages/SharedManagement';
import SharedManagementSingle from './pages/SharedManagementSingle';
import AllAppointments from './pages/AllAppointments';
import Appointments from './pages/Home';
import NewAppointment from './pages/NewAppointment';
import EditAppointment from './pages/EditAppointment';
import AppVersions from './components/AppVersions';
// import BarCodeScannerDemo from './components/BarCodeScannerDemo';
import NewAppVersion from './pages/NewAppVersion';
import EditAppVersion from './pages/EditAppVersion';
// import SharedAppointment from './pages/components/SharedAppointment';
import SharedAppointment from './components/SharedAppointment';
import AppointmentTypes from './components/AppointmentTypes';
import NewAppointmentType from './pages/NewAppointmentType';
import EditAppointmentType from './pages/EditAppointmentType';
import Login from './pages/Login';
import About from './pages/About';
import TalkToUs from './pages/TalkToUs';
import SharedAppointmentMessages from './components/SharedAppointmentMessages';

import Privacy from './pages/Privacy';
import Configurations from './pages/Configurations';
import Timezones from './pages/Timezones';
import TermsAndConditions from './pages/TermsAndConditions';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ChangePassword from './pages/ChangePassword';
import NewUser from './pages/NewUser';
import AllUsers from './pages/AllUsers';
import EditUser from './pages/EditUser';
import UserConfirmation from './pages/UserConfirmation';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      {/* <Route exact path="/barcode-demo" component={BarCodeScannerDemo} /> */}
      <Route exact path="/cherry-pick/:selectedDate" component={Home} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password/:code" component={ResetPassword} />
      <Route exact path="/change-password" component={ChangePassword} />
      <Route exact path="/shared" component={Shared} />
      <Route exact path="/shared-messages/:appointmentId" component={SharedAppointmentMessages} />
      <Route exact path="/shared-management" component={SharedManagement} />
      <Route exact path="/shared-management/single/:id" component={SharedManagementSingle} />
      <Route exact path="/shared/:id" component={SharedAppointment} />
      <Route exact path="/active" component={Home} />
      <Route exact path="/all" component={AllAppointments} />
      <Route exact path="/appointments/:viewerUserId" component={Appointments} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/about" component={About} />
      <Route exact path="/talk-to-us" component={TalkToUs} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/configurations" component={Configurations} />
      <Route exact path="/timezones" component={Timezones} />
      <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
      {/* <Route exact path="/new/:appointmentCategoryId" component={NewAppointment} /> */}
      {/* <Route exact path="/edit/:id" component={EditAppointment}/> */}
      <Route exact path="/new" component={NewAppointment} />
      <Route exact path="/edit/:id" component={EditAppointment} />
      <Route exact path="/app-versions" component={AppVersions} />
      <Route exact path="/app-versions/new" component={NewAppVersion} />
      <Route exact path="/app-versions/edit/:id" component={EditAppVersion} />
      <Route exact path="/" component={Home} />
      <Route exact path="/users/new" component={NewUser} />
      <Route exact path="/users/edit/:id" component={EditUser} />
      <Route exact path="/users/confirm/:code" component={UserConfirmation} />
      <Route exact path="/users/all" component={AllUsers} />
      <Route exact path="/appointment-types" component={AppointmentTypes} />
      <Route exact path="/appointment-types/edit/:id" component={EditAppointmentType} />
      <Route exact path="/appointment-types/new/:appointmentCategoryId" component={NewAppointmentType} />
    </Switch>
  );
}