import { useState } from 'react';
import { Form, ButtonContainer, Container } from './styles';
import FormGroup from '../FormGroup';
import Input from '../Input';
import Button from '../Button';
import useErrors from '../../hooks/useErrors';
import Loader from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
  // faDollarSign,
} from '@fortawesome/free-solid-svg-icons'
import OrLine from '../../assets/images/new-layout/register_or.svg'
import Pdf from '../../assets/TELEMBRO_TERMOS_DE_USO_E_NAVEGACAO_E_POLITICA_DE_PRIVACIDADE.pdf'

export default function UserForm({
  onSubmit,
}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = (name && email && password && errors.length === 0);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateUserSuccess, setIsCreateUserSuccess] = useState(false);
  const [serviceError, setServiceError] = useState('');

  function isPasswordValid(password) {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return regex.test(password);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);

    const response = await
      onSubmit({
        name,
        email,
        password
      });


    if (response.Error) {
      setServiceError(response.Error.Message);
    }
    else {
      setServiceError(null);
    }

    setIsCreateUserSuccess(true);
    setIsLoading(false);
  }

  async function handleGoToLogin(e) {
    e.preventDefault();
    e.stopPropagation();

    window.open('/login', '_self');
  }

  function handleChangeName(e) {
    setName(e.target.value);

    if (!e.target.value) {
      setError({ field: 'name', message: 'o nome é obrigatório' });
    } else {
      removeError('name');
    }
  }

  function handleChangeEmail(e) {
    setEmail(e.target.value);

    if (!e.target.value) {
      setError({ field: 'email', message: 'o e-mail é obrigatório' });
    } else {
      removeError('email');
    }
  }

  function handleChangePassword(e) {
    setPassword(e.target.value);

    if (!e.target.value) {
      setError({ field: 'password', message: 'a senha é obrigatória' });
    } else {
      if (confirmPassword && (confirmPassword !== e.target.value)) {
        setError({ field: 'password', message: 'digite a mesma senha' });
        return;
      }
      else if (!isPasswordValid(e.target.value)) {
        setError({ field: 'password', message: 'a senha deve conter um mínimo de 8 letras, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula e pelo menos um número' });
      }
      else {
        removeError('password');
        removeError('confirmPassword');
      }
    }
  }

  function handleChangeConfirmePassword(e) {
    setConfirmPassword(e.target.value);

    if (!e.target.value) {
      setError({ field: 'confirmPassword', message: 'digite a mesma senha novamente' });
    } else {

      if (password !== e.target.value) {
        setError({ field: 'confirmPassword', message: 'digite a mesma senha novamente' });
        return;
      }
      else if (!isPasswordValid(e.target.value)) {
        setError({ field: 'confirmPassword', message: 'a senha deve conter um mínimo de 8 letras, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula e pelo menos um número' });
      }
      else {
        removeError('password');
        removeError('confirmPassword');
      }
    }
  }

  return (
    (!isCreateUserSuccess) ? (
      <div style={{ paddingBottom: '32px' }}>
        <Form onSubmit={handleSubmit} noValidate>
          <FormGroup
            error={getErrorMessageByFieldName('name')}
          >
            <div className='form-label'>Nome</div>
            <Input
              type="text"
              value={name}
              placeholder="Nome"
              onChange={handleChangeName}
              error={getErrorMessageByFieldName('name')}
            />
          </FormGroup>

          <FormGroup
            error={getErrorMessageByFieldName('email')}
          >
            <div className='form-label'>E-mail</div>
            <Input
              type="text"
              value={email}
              placeholder="E-mail"
              onChange={handleChangeEmail}
              error={getErrorMessageByFieldName('email')}
            />
          </FormGroup>

          <FormGroup
            error={getErrorMessageByFieldName('password')}
          >
            <div className='form-label'>Senha</div>
            <div className="input-group-btn">
              <Input
                type={passwordType}
                value={password}
                placeholder="Senha"
                onChange={handleChangePassword}
                error={getErrorMessageByFieldName('password')}
              />
              <button type="button" className="btn" onClick={togglePassword}>
                {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
              </button>
            </div>
          </FormGroup>

          <FormGroup
            error={getErrorMessageByFieldName('confirmPassword')}
          >
            <div className='form-label'>Confirmar senha</div>
            <div className="input-group-btn">
              <Input
                type={passwordType}
                value={confirmPassword}
                placeholder="Confirmar senha"
                onChange={handleChangeConfirmePassword}
                error={getErrorMessageByFieldName('confirmPassword')}
              />
              <button type="button" className="btn" onClick={togglePassword}>
                {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
              </button>
            </div>
          </FormGroup>

          <Loader isLoading={isLoading} />

          <ButtonContainer>
            <Button
              type="button"
              disabled={!isFormValid}
              onClick={(e) => handleSubmit(e)}

            >
              Cadastrar
            </Button>

            <div className='small-legal-disclaimer'>
              Ao prosseguir, você concorda com as diretrizes descritas em nossa <br /><a rel='noreferrer' target='_blank' href={Pdf}>Política de privacidade e Termos de uso e navegação</a>
            </div>

          </ButtonContainer>

          <ButtonContainer>
            <img src={OrLine} alt='Ou' />
          </ButtonContainer>

          <ButtonContainer>
            <Button
              type="button"
              secondary
              onClick={(e) => window.open('/login', '_self')}

            >
              Logar no app
            </Button>
          </ButtonContainer>

        </Form>
      </div>

    ) : (
      <Container>
        <h5>
          {(serviceError) ? (
            <>
              {serviceError}
            </>
          ) : (
            <>
              Parabéns! Confirme seu cadastro acessando a caixa de entrada do seu e-mail e em seguida, clique aqui para realizar o seu login
            </>
          )}
        </h5>

        {(serviceError) ? (
          <ButtonContainer>
            <Button
              type="button"
              onClick={(e) => setIsCreateUserSuccess(false)}
            >
              Alterar os dados e tentar novamente
            </Button>
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <Button
              type="button"
              onClick={(e) => handleGoToLogin(e)}
            >
              Clique aqui para fazer login
            </Button>
          </ButtonContainer>
        )}
      </Container>
    )
  );
}