import { useParams } from 'react-router-dom';
import AppointmentsService from '../../services/AppointmentsService';
import SharedAppointmentsService from '../../services/SharedAppointmentsService';
import { useState, useEffect } from "react";
import {
  Container, Card, CardsContainer
  // , CheckboxContainer
} from './styles';
import Button from '../Button';
import Trash from '../../assets/icons/trash.svg';
import Share from '../../assets/icons/share.svg';
import Calendar from '../../assets/icons/event.svg';
import Notifications from '../../assets/icons/notifications.svg';
import Info from '../../assets/icons/info.svg';
import Loader from '../../components/Loader';
import { format } from 'date-fns';
import Config from '../../config/environments/local';
import { localGet } from "../../lib/session";
import ModalShareEmail from '../ModalShareEmail';

export default function SharedAppointment() {

  const [appointment, setAppointment] = useState({});
  const [sharedWithUsers, setSharedWithUsers] = useState([]);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  const [checkedAppointments, setCheckedAppointments] = useState([]);
  // const [appointmentToShare, setAppointmentToShare] = useState(null);

  // const [sharedEmail, setSharedEmail] = useState('');
  const [sharedEmail, setSharedEmail] = useState([]);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const {
    id,
    // viewerUserId,
  } = useParams();

  // function handleChangeEmail(e) {
  //   setSharedEmail(e.target.value);
  // }

  function handleChangeEmail(emails) {
    setSharedEmail(emails);
  }

  async function addAppointmentToShare(appointmentId) {
    const appointmentAlreadyExists = checkedAppointments.find((checkedAppointmentId) => checkedAppointmentId === appointmentId);

    if (!appointmentAlreadyExists) {
      //adiciona o appointment
      setCheckedAppointments((prevState) => [
        ...prevState,
        appointmentId
      ]);
    }
  }

  async function handleDelete(sharedAppointmentId) {
    try {
      setIsLoading(true);

      const response = await new SharedAppointmentsService(Config.API_BASE_URL).delete({
        Id: sharedAppointmentId,
      });

      console.log(response);

      if (response.Error !== null) {
        window.open(`/shared/${id}`, '_self');
      }
      else {
        window.open(`/shared/${id}`, '_self');
      }

      // setAppointment(response.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function getAppointment() {
      try {
        setIsLoading(true);

        const response = await new AppointmentsService(Config.API_BASE_URL).getAppointment(id);

        response.Result.AppointmentDate = format(new Date(response.Result.AppointmentDate), 'dd/MM/yyyy');

        if (response.Result.AppointmentRecurrenceEndDate) {
          response.Result.AppointmentRecurrenceEndDate = format(new Date(response.Result.AppointmentRecurrenceEndDate), 'dd/MM/yyyy');
        }

        if (response.Result.ReminderDates) {
          for (let i = 0; i < response.Result.ReminderDates.length; i++) {
            const formattedDate = format(new Date(response.Result.ReminderDates[i]), 'dd/MM/yyyy');
            response.Result.ReminderDates[i] = formattedDate;
          }
        }

        setAppointment(response.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }

    async function getSharedWithUsers() {
      try {
        setIsLoading(true);

        const response = await new SharedAppointmentsService(Config.API_BASE_URL).getByAppointmentIdAndOwnerUserId(id, sessionObject.userId);

        setSharedWithUsers(response.Result);

      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }

    getAppointment();
    getSharedWithUsers();
  }, [id, sessionObject.userId]);

  async function handleOpenShareModal(e, appointment) {
    await addAppointmentToShare(appointment.Id);
    setIsShareModalOpen(true);
    console.log(appointment);
  }

  async function handleShare(e) {
    setIsShareModalOpen(true);
    setIsLoading(true);

    const ShareAppointmentsResponse = await new SharedAppointmentsService(Config.API_BASE_URL).shareAppointments({
      AppointmentsIds: checkedAppointments,
      SharedUserEmail: sharedEmail,
    });

    console.log(ShareAppointmentsResponse);

    if (ShareAppointmentsResponse.Error) {
      alert("Ocorreu um erro ao compartilhar");
      console.log('Ocorreu um erro ao compartilhar');
    }
    else {
      console.log(ShareAppointmentsResponse.Error);
      alert("Compromissos compartilhados com sucesso!");
      window.open('/', '_self');
    }
    // window.open(`/shared/${appointmentToShare.Id}`, '_self');

    setIsLoading(false);
    setIsShareModalOpen(false);
  }

  function handleCloseShareModal(e, appointment) {
    setIsShareModalOpen(false);
  }

  return (
    <>
      <Container>
        <Loader isLoading={isLoading} />

        <ModalShareEmail
          title='E-mail'
          isVisible={isShareModalOpen}
          onConfirm={handleShare}
          onCancel={handleCloseShareModal}
          emailValue={sharedEmail}
          onChangeEmail={handleChangeEmail}
        />

        <CardsContainer>
          {appointment && (
            <Card
              key={`id-${appointment.Id}`}
            >
              <div className="info">
                <div className="text">
                  {/* <div className="appointment-name">
            <strong>{appointment.AppointmentCategoryName}</strong>
          </div> */}

                  {appointment.Name && (
                    <div className="span type">
                      <b>{appointment.Name}</b>
                    </div>
                  )}

                  <div className="span type">
                    {appointment.AppointmentTypeName}
                  </div>

                  <div className="span">
                    {/* <FontAwesomeIcon icon={faCalendarDay} /> */}
                    <img src={Calendar} alt='date' />
                    {/* {format(new Date(appointment.AppointmentDate), 'dd/MM/yyyy')} */}
                    {appointment.AppointmentDate}

                    {appointment.AppointmentTime && (
                      ` - ${appointment.AppointmentTime}`
                    )}
                  </div>

                  {(appointment.ReminderDates && appointment.ReminderDates[0]) && (
                    <div className="span">
                      {
                        appointment.ReminderDates[0] && (
                          <>
                            {/* <FontAwesomeIcon icon={faBell} /> */}
                            <img src={Calendar} alt='date' />
                            {appointment.ReminderDates[0]}
                          </>
                        )
                      }

                      {
                        appointment.ReminderDates[1] && (
                          <>
                            , {appointment.ReminderDates[1]}
                          </>
                        )
                      }

                      {
                        appointment.ReminderDates[2] && (
                          <>
                            &nbsp;e {appointment.ReminderDates[2]}
                          </>
                        )
                      }

                    </div>
                  )}

                  {/* {
            (appointment.DocumentValue !== 0) && (appointment.DocumentValue) && (
              <>
                <div className="span">
                  {appointment.DocumentValuestring}
                </div>
              </>
            )
          } */}

                  {appointment.Observations && (
                    <div className="span">
                      <img src={Info} alt='Info' />
                      {appointment.Observations}
                    </div>
                  )}

                  {appointment.AppointmentHasRecurrences && (
                    <div className="span">
                      <img src={Notifications} alt='Loop' />
                      Recorrente
                    </div>
                  )}

                </div>
                <div className="share">
                  {/* <CheckboxContainer
                    className='toggleWrapper'
                  >
                    <input
                      id={appointment.Id}
                      type="checkbox"
                      // className='mobileToggle'
                      key={appointment.Id}
                      checked={(checkedAppointments.includes(appointment.Id))}
                      onChange={(e) => {
                        handleCheckboxChange(appointment.Id);
                      }}
                    />
                    <label htmlFor={appointment.Id}></label>
                  </CheckboxContainer> */}
                  {(!appointment.SharedAppointmentId) && (
                    <>
                      <button
                        type="button"
                        onClick={(e) => {
                          handleOpenShareModal(e, appointment);
                        }}
                      >
                        <img src={Share} alt='Share' />
                      </button>
                    </>
                  )}
                </div>
              </div>

              <div className="shared-users-container">
                <div className='title'>
                  Compromisso compartilhado com
                </div>
                <div className='shared-users-cards'>
                  {sharedWithUsers && (
                    <>
                      {
                        sharedWithUsers.map((sharedAppointment) => (
                          <>
                            <div className='shared-user-card'>
                              <div className='name'>
                                {sharedAppointment.SharedUserName ? sharedAppointment.SharedUserName : sharedAppointment.SharedUserEmail}
                              </div>
                              <div className='actions'>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    handleDelete(sharedAppointment.Id)
                                  }}
                                >
                                  <img src={Trash} alt='Trash' />
                                </button>
                              </div>
                            </div>
                          </>
                        ))
                      }
                    </>
                  )}
                </div>
              </div>
            </Card>
          )}
        </CardsContainer>

        <div className="fab-container">
          <div
            className="button-container iconbutton"
          // onClick={handleOptions}
          >
            {/* <span className='icon'>
            <img src={PlusSign} alt='Plus sign' className={`${plusSignClass}`} />
          </span> */}
            <Button
              type='button'
              className='button'
              onClick={() => window.open(`/shared`, '_self')}
            >
              Ver compartilhamentos
            </Button>
          </div>
          <div
            className="button-container iconbutton"
          // onClick={handleOptions}
          >
            {/* <span className='icon'>
            <img src={PlusSign} alt='Plus sign' className={`${plusSignClass}`} />
          </span> */}
            <Button
              type='button'
              secondary
              className='button secondary'
              onClick={() => window.open(`/all`, '_self')}
            >
              Relatório
            </Button>
          </div>
        </div >
      </Container>
    </>
  )
};