/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import { Overlay } from './styles';
// import Button from '../Button';
import { localGet } from "../../lib/session";
import { useState } from "react";
import Config from "../../config/environments/local";

import CloseButton from '../../assets/icons/menu/close.svg'
import AccountCircle from '../../assets/icons/menu/account_circle.svg'
import Logo from '../../assets/icons/menu/logotipo.svg'
import Facebook from '../../assets/icons/menu/facebook-f-brands.svg'
import Instagram from '../../assets/icons/menu/instagram-brands.svg'
import Linkedin from '../../assets/icons/menu/linkedin-in-brands.svg'
import Youtube from '../../assets/icons/menu/youtube-brands.svg'

export default function SideBar({ isVisible, onClose, onLogOut, onOpenModalCancelUserRegistration, showUserLink }) {
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Overlay>
        <div id="mySidenav" className="sidenav">
          <div className='close-button-container'>
            <button
              onClick={onClose}
            >
              <img src={CloseButton} alt='filter' />
            </button>
          </div>
          <div className='name-container'>
            <div className='image'><img src={AccountCircle} alt='filter' /></div>
            <div className='name'>{sessionObject.userName}</div>
          </div>
          <div className='links-container'>
            <>
              <a href="/">Versão {Config.TELEMBRO_VERSION}</a>
            </>
            {/* {(Config.ENVIRONMENT === 'hml') && (
              <>
                <a href="/">Versão {Config.TELEMBRO_VERSION}</a>
              </>
            )} */}
            {(showUserLink) && (
              <>
                <a href="/users/all">Ver usuários</a>
                <a href="/app-versions/">Versões do app</a>
              </>
            )}
            <a href="/">Compromissos</a>
            <a href="/shared">Compartilhamentos</a>
            <a href="/appointment-types">Gerenciar tipos</a>
            <a href="/all">Pesquisa/Relatório</a>
            <a href="https://www.youtube.com/channel/UCrriL5dwHWOSOs9ZpvJtgag" target="_blank" rel="noreferrer">Tutorial</a>
            <a href="/change-password">Trocar senha</a>
            <a href="/timezones">Fuso horário</a>
            <a href="/configurations">Push notifications</a>
            <a href="/talk-to-us">Fale Conosco</a>
            <a href="/about">Sobre</a>
            <button
              onClick={onLogOut}
            >
              Fazer logout
            </button>
            <button
              onClick={(e) => {
                onClose();
                onOpenModalCancelUserRegistration();
              }}
            >
              Cancelar cadastro
            </button>
            <div className='footer-container'>
              <img src={Logo} alt='filter' />
            </div>
          </div>
          {/* <div className='footer-container'>
            <img src={Logo} alt='filter' />
          </div> */}
          <div className='social-container'>
            <a href="https://www.facebook.com/telembroapp" target="_blank" rel="noreferrer">
              <img src={Facebook} alt='filter' />
            </a>
            <a href="https://www.instagram.com/telembro.app/" target="_blank" rel="noreferrer">
              <img src={Instagram} alt='filter' />
            </a>
            <a href="https://www.linkedin.com/company/telembro-app/" target="_blank" rel="noreferrer">
              <img src={Linkedin} alt='filter' />
            </a>
            <a href="https://www.youtube.com/channel/UCrriL5dwHWOSOs9ZpvJtgag" target="_blank" rel="noreferrer">
              <img src={Youtube} alt='filter' />
            </a>
          </div>
        </div>
      </Overlay>
    </>,
    document.getElementById('sidebar-root')
  );
}

SideBar.propTypes = {
  onClose: PropTypes.func.isRequired,
  onLogOut: PropTypes.func.isRequired,
  showUserLink: PropTypes.bool.isRequired,
}

SideBar.defaultProps = {
  isVisible: true,
}