/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Container,
  Card,
  // ErrorContainer,
  CardsContainer,
  Form,
} from './styles';
import FormGroup from "../../components/FormGroup";
import Select from "../../components/Select";
import Loader from '../Loader';
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { format } from 'date-fns';
// import Button from '../Button';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'
import Calendar from '../../assets/icons/event.svg';
import Notifications from '../../assets/icons/notifications.svg';
import Info from '../../assets/icons/info.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import SharedAppointmentsService from '../../services/SharedAppointmentsService';

export default function SharedAppointmentsManagement({ appointmentId }) {
  const [sharedAppointments, setSharedAppointments] = useState([]);
  const [approveSharedAppointments, setApprovedSharedAppointments] = useState([]);
  const [showPending, setshowPending] = useState(true);
  const [approvalTypeToShow, setApprovalTypeToShow] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError] = useState(false);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  const listAppointments = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      if (appointmentId) {
        const appointmentsList = await new SharedAppointmentsService(Config.API_BASE_URL).getSinglePendingOfApproval(sessionObject.userId, appointmentId);
        if (appointmentsList.Result) {
          setSharedAppointments([appointmentsList.Result]);
        }
        else {
          setSharedAppointments([]);
        }
      }
      else {
        const appointmentsList = await new SharedAppointmentsService(Config.API_BASE_URL).getAllPendingOfApproval(sessionObject.userId);
        setSharedAppointments(appointmentsList.Result);
      }
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject, appointmentId]);

  const listApprovedAppointments = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      const appointmentsList = await new SharedAppointmentsService(Config.API_BASE_URL).getAllApproved(sessionObject.userId);
      setApprovedSharedAppointments(appointmentsList.Result);

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject]);

  async function handleReloadPending() {
    await listAppointments();
  };

  async function handleReloadApproved() {
    await listApprovedAppointments();
  };

  useEffect(() => {
    if (!sessionObject) {
      window.open('/login', '_self');
    }

    listAppointments();
    listApprovedAppointments();
  }, [listAppointments, listApprovedAppointments, sessionObject]);

  async function handleApprove(e, sharedAppointmentId) {
    try {
      setIsLoading(true);
      await new SharedAppointmentsService(Config.API_BASE_URL).manageApproval({
        Id: sharedAppointmentId,
        IsAccepted: true,
      });
      handleReloadPending();
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRemoveApproval(e, sharedAppointmentId) {
    try {
      setIsLoading(true);
      await new SharedAppointmentsService(Config.API_BASE_URL).removeApproval({
        Id: sharedAppointmentId,
        IsAccepted: false,
      });
      // if (showPending) {
      //   handleReloadPending();
      // }
      // else {
      //   handleReloadApproved();
      // }
      handleReloadApproved();

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRefuse(e, sharedAppointmentId) {
    try {
      setIsLoading(true);
      await new SharedAppointmentsService(Config.API_BASE_URL).manageApproval({
        Id: sharedAppointmentId,
        IsAccepted: false,
      });
      handleReloadPending();
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleChangeApprovalTypeToShow(e) {
    setApprovalTypeToShow(e.target.value);
    if (e.target.value === '1') {
      setshowPending(true);
      handleReloadPending();
    }
    else {
      setshowPending(false);
      handleReloadApproved();
    }
  }

  return (
    <Container>
      <Loader isLoading={isLoading} />

      <div className='info-page'>
        <div className="image-header">
          <img src={CalendarImage} alt='telembro' />
        </div>
        <div className="">
          <Form noValidate>
            <Loader isLoading={isLoading} />
            <div>
              <FormGroup>
                <div className='form-label'>Exibir compromissos</div>
                <Select
                  value={approvalTypeToShow}
                  onChange={handleChangeApprovalTypeToShow}
                  placeholder='Selecione uma opção'
                >
                  <option value="1">Pendentes de aprovação</option>
                  <option value="2">Aprovados</option>
                </Select>
              </FormGroup>
            </div>
          </Form >
        </div >

        {(showPending) ? (
          <>
            {(sharedAppointments && sharedAppointments.length > 0) ? (
              <div className="text-header">
                Aprove ou recuse o compartilhamento&nbsp;
                {sharedAppointments.length === 1 ? ' deste ' : ' destes '}
                {sharedAppointments.length}
                {sharedAppointments.length === 1 ? ' compromisso' : ' compromissos'}
              </div>
            ) : (
              <div className="text-header">
                Não há compromissos pendentes de aprovação
              </div>
            )}
          </>
        ) : (
          <>
            {(approveSharedAppointments && approveSharedAppointments.length > 0) ? (
              <div className="text-header">
                Mantenha ou recuse o compartilhamento&nbsp;
                {approveSharedAppointments.length === 1 ? ' deste ' : ' destes '}
                {approveSharedAppointments.length}
                {approveSharedAppointments.length === 1 ? ' compromisso' : ' compromissos'}
              </div>
            ) : (
              <div className="text-header">
                Não há compromissos aprovados
              </div>
            )}
          </>
        )}

      </div>

      {/* {hasError && (
        <ErrorContainer>
          <strong>
            Ocorreu um erro ao retornar os compromissos
          </strong>
          <Button
            type="button"
            onClick={(e) => { handleReload(); }}
          >
            Tentar novamente
          </Button>
        </ErrorContainer>
      )} */}

      {!hasError && (
        <>
          <CardsContainer>
            {(showPending) && (
              <>
                {sharedAppointments && (
                  sharedAppointments.map((sharedAppointment) => (
                    <Card
                      key={`id-${sharedAppointment.Appointment.Id}`}
                    >
                      <div className="info">
                        <div className="text">
                          <div className="appointment-name">
                            <strong>{sharedAppointment.Appointment.Name}</strong>
                          </div>

                          <div className="span type">
                            {sharedAppointment.Appointment.AppointmentTypeName}
                          </div>

                          <div className="span">
                            <img src={Calendar} alt='date' />
                            {format(new Date(sharedAppointment.Appointment.AppointmentDate), 'dd/MM/yyyy')}

                            {sharedAppointment.Appointment.AppointmentTime && (
                              ` - ${sharedAppointment.Appointment.AppointmentTime}`
                            )}
                          </div>

                          {(sharedAppointment.Appointment.ReminderDates && sharedAppointment.Appointment.ReminderDates[0]) && (
                            <div className="span">
                              {
                                sharedAppointment.Appointment.ReminderDates[0] && (
                                  <>
                                    <img src={Calendar} alt='date' />
                                    {format(new Date(sharedAppointment.Appointment.ReminderDates[0]), 'dd/MM/yyyy')}
                                  </>
                                )
                              }

                              {
                                sharedAppointment.Appointment.ReminderDates[1] && (
                                  <>
                                    , {format(new Date(sharedAppointment.Appointment.ReminderDates[1]), 'dd/MM/yyyy')}
                                  </>
                                )
                              }

                              {
                                sharedAppointment.Appointment.ReminderDates[2] && (
                                  <>
                                    &nbsp;e {format(new Date(sharedAppointment.Appointment.ReminderDates[2]), 'dd/MM/yyyy')}
                                  </>
                                )
                              }

                            </div>
                          )}

                          {sharedAppointment.Appointment.Observations && (
                            <div className="span">
                              <img src={Info} alt='Info' />
                              {sharedAppointment.Appointment.Observations}
                            </div>
                          )}

                          {sharedAppointment.Appointment.AppointmentHasRecurrences && (
                            <div className="span">
                              <img src={Notifications} alt='Loop' />
                              Recorrente
                            </div>
                          )}

                        </div>

                      </div>

                      <div className="actions">
                        <a
                          href='#a'
                          className="confirm"
                          onClick={(e) => {
                            handleApprove(e, sharedAppointment.Id)
                          }}
                        >
                          <FontAwesomeIcon icon={faCheckCircle} />&nbsp;&nbsp;
                        </a>
                        <a
                          href='#r'
                          className="refuse"
                          onClick={(e) => {
                            handleRefuse(e, sharedAppointment.Id)
                          }}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />&nbsp;&nbsp;
                        </a>
                      </div>
                    </Card>
                  ))
                )}
              </>
            )}
            {(!showPending) && (
              <>
                {approveSharedAppointments && (
                  approveSharedAppointments.map((sharedAppointment) => (
                    <Card
                      key={`id-${sharedAppointment.Appointment.Id}`}
                    >
                      <div className="info">
                        <div className="text">
                          <div className="appointment-name">
                            <strong>{sharedAppointment.Appointment.Name}</strong>
                          </div>

                          <div className="span type">
                            {sharedAppointment.Appointment.AppointmentTypeName}
                          </div>

                          <div className="span">
                            <img src={Calendar} alt='date' />
                            {format(new Date(sharedAppointment.Appointment.AppointmentDate), 'dd/MM/yyyy')}

                            {sharedAppointment.Appointment.AppointmentTime && (
                              ` - ${sharedAppointment.Appointment.AppointmentTime}`
                            )}
                          </div>

                          {(sharedAppointment.Appointment.ReminderDates && sharedAppointment.Appointment.ReminderDates[0]) && (
                            <div className="span">
                              {
                                sharedAppointment.Appointment.ReminderDates[0] && (
                                  <>
                                    <img src={Calendar} alt='date' />
                                    {format(new Date(sharedAppointment.Appointment.ReminderDates[0]), 'dd/MM/yyyy')}
                                  </>
                                )
                              }

                              {
                                sharedAppointment.Appointment.ReminderDates[1] && (
                                  <>
                                    , {format(new Date(sharedAppointment.Appointment.ReminderDates[1]), 'dd/MM/yyyy')}
                                  </>
                                )
                              }

                              {
                                sharedAppointment.Appointment.ReminderDates[2] && (
                                  <>
                                    &nbsp;e {format(new Date(sharedAppointment.Appointment.ReminderDates[2]), 'dd/MM/yyyy')}
                                  </>
                                )
                              }

                            </div>
                          )}

                          {sharedAppointment.Appointment.Observations && (
                            <div className="span">
                              <img src={Info} alt='Info' />
                              {sharedAppointment.Appointment.Observations}
                            </div>
                          )}

                          {sharedAppointment.Appointment.AppointmentHasRecurrences && (
                            <div className="span">
                              <img src={Notifications} alt='Loop' />
                              Recorrente
                            </div>
                          )}

                        </div>

                      </div>

                      <div className="actions">
                        <a
                          href='#r'
                          className="refuse"
                          onClick={(e) => {
                            handleRemoveApproval(e, sharedAppointment.Id)
                          }}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />&nbsp;&nbsp;
                        </a>
                      </div>
                    </Card>
                  ))
                )}
              </>
            )}

            {/* approveSharedAppointments */}
          </CardsContainer>
        </>
      )
      }
      <div style={{ height: '75px' }}></div>
    </Container >
  );
}