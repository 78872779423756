import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'
// import ShieldI from '../../assets/icons/shield_i.svg'
// import ShieldPerson from '../../assets/icons/shield_person.svg'

export default function TalkToUs() {
  return (
    <>
      <div className='info-page'>
        <div className="image-header talktous">
          <img src={CalendarImage} alt='telembro' />
        </div>
        <div className="text-header">
          Como podemos ajudar?
          <br />
          <br />
          Entre em contato conosco e tire suas dúvidas sobre o TeLembro.
        </div>

        <div
          className="button-container"
        >
          <button
            type='button'
            className='button'
            onClick={() => window.location.href = "mailto:info@telembro.com"}
            onKeyDown={() => window.location.href = "mailto:info@telembro.com"}
          >
            Enviar email Fale Conosco
          </button>
        </div>

        <div className="text-header">
          <br />
          <br />
          Fale com o DPO.
        </div>

        <div
          className="button-container"
        >
          <button
            type='button'
            className='button'
            onClick={() => window.location.href = "mailto:telembro.dpo@gmail.com"}
            onKeyDown={() => window.location.href = "mailto:telembro.dpo@gmail.com"}
          >
            Enviar email DPO
          </button>
        </div>
        {/* <div
          className="button-container"
        >
          <button
            type='button'
            className='button secondary'
            onClick={() => window.open('/', '_self')}
            onKeyDown={() => window.open('/', '_self')}
          >
            Whatsapp
          </button>
        </div> */}
      </div>
    </>
  )
};