import LoginForm from "../../components/LoginForm";
// import { Container, Header } from './styles';
import BigLogo from '../../assets/icons/menu/big-logo.svg'

export default function Login() {
  return (
    <>
      <div className="big-logo-container">
        <img src={BigLogo} alt='logo' />
      </div>

      <LoginForm
      />
    </>
  )
};