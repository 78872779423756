import HttpClient from './utils/HttpClient';

class PushNotificationsService {
  constructor(baseUrl) {

    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async GetConfigurations(data) {
    const urlPath = `/pushnotifications/configurations/get`;

    return this.httpClient.post(urlPath, data);
  }

  async ActivateGlobal(data) {
    const urlPath = `/pushnotifications/activate/global`;

    return this.httpClient.post(urlPath, data);
  }
  async DeactivateGlobal(data) {
    const urlPath = `/pushnotifications/deactivate/global`;

    return this.httpClient.post(urlPath, data);
  }

  async ActivateAppointments(data) {
    const urlPath = `/pushnotifications/activate/appointments`;

    return this.httpClient.post(urlPath, data);
  }
  async DeactivateAppointments(data) {
    const urlPath = `/pushnotifications/deactivate/appointments`;

    return this.httpClient.post(urlPath, data);
  }

  async ActivateSingleAppointment(data) {
    const urlPath = `/pushnotifications/activate/appointment/single`;

    return this.httpClient.post(urlPath, data);
  }
  async DeactivateSingleAppointment(data) {
    const urlPath = `/pushnotifications/deactivate/appointment/single`;

    return this.httpClient.post(urlPath, data);
  }
}

export default PushNotificationsService;