/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Container, ErrorContainer
} from './styles';
import Loader from '../Loader';
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
// import { format } from 'date-fns';
import AppointmentsService from '../../services/AppointmentsService';
// import Button from '../Button';
import Button from '../Button';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
import PropTypes from 'prop-types';

export default function CalendarVision({ selectedDate, setSelectedDate, setCurrentYear }) {
  const [calendarAppointments, setCalendarAppointments] = useState({});
  // const [isLoading, setIsLoading] = useState(true);
  const [hasError] = useState(false);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [activeDay, setActiveDay] = useState({});

  const getCalendarVision = useCallback(async (dateSelected) => {
    if (!sessionObject || !dateSelected) {
      return;
    }

    try {
      const getCalendarVisionResponse = await new AppointmentsService(Config.API_BASE_URL).GetCalendarVision(
        {
          UserId: sessionObject.userId,
          SelectedDate: dateSelected,
          // SelectedDate: '2023-12-10',
        }
      );
      setCalendarAppointments(getCalendarVisionResponse.Result);
      const activeWeek = getCalendarVisionResponse.Result.Weeks.filter(f => f.IsActiveWeek === true);
      const activeDay = activeWeek[0].WeekDays.filter(f => f.IsActiveDay === true);
      setActiveDay(activeDay[0]);
      setCurrentYear(getCalendarVisionResponse.Result.SelectedDateYear);
    } catch (error) {
      console.log('error ', error);
    } finally {

    }
  }, [sessionObject, setCalendarAppointments, setCurrentYear]);

  // async function handleReload() {
  //   await getCalendarVision();
  // };

  const handleReload = useCallback(async () => {
    await getCalendarVision(setSelectedDate);
  }, [getCalendarVision, setSelectedDate]);

  async function handleChangeSelectedDate(dateSelected) {
    console.log(dateSelected);
    setSelectedDate(dateSelected);
    await getCalendarVision(dateSelected);
  };

  useEffect(() => {
    getCalendarVision(selectedDate);
  }, [getCalendarVision, selectedDate]);

  function getWeekDayWidth(weekDays, isLastOne) {
    switch (weekDays) {
      case 2:
        if (isLastOne) {
          return 'quarentaeoito';
        }
        else {
          return 'quarentaeoito';
        }
      case 3:
        if (isLastOne) {
          return 'trinta';
        }
        else {
          return 'trinta';
        }
      case 4:
        if (isLastOne) {
          return 'quarentaeoito';
        }
        else {
          return 'quarentaeoito';
        }
      case 5:
        if (isLastOne) {
          return 'cem';
        }
        else {
          return 'quarentaeoito';
        }
      case 6:
        if (isLastOne) {
          return 'trinta';
        }
        else {
          return 'trinta';
        }
      case 7:
        if (isLastOne) {
          return 'trinta';
        }
        else {
          return 'trinta';
        }
      default:
        return 'cem';
    }
  }

  // function getFullMonthName(date) {

  //   // date = isNaN(date) ? new Date() : new Date(`${date} `);

  //   const formatter = new Intl.DateTimeFormat('pt-BR', { caseFirst: "upper", month: 'long' });
  //   const monthName = formatter.format(new Date(date));

  //   switch (monthName) {
  //     case 'janeiro':
  //       return 'Janeiro';
  //     case 'fevereiro':
  //       return 'Fevereiro';
  //     case 'março':
  //       return 'Março';
  //     case 'abril':
  //       return 'Abril';
  //     case 'maio':
  //       return 'Maio';
  //     case 'junho':
  //       return 'Junho';
  //     case 'julho':
  //       return 'Julho';
  //     case 'agosto':
  //       return 'Agosto';
  //     case 'setembro':
  //       return 'Setembro';
  //     case 'outubro':
  //       return 'Outubro';
  //     case 'novembro':
  //       return 'Novembro';
  //     case 'dezembro':
  //       return 'Dezembro';
  //     default:
  //       return '-';
  //   }
  // }

  return (
    <Container>
      {/* <Loader isLoading={isLoading} /> */}
      <Loader isLoading={false} />

      <div className="fab-container">
        <div
          className="button-container iconbutton"
        >
          <Button
            type='button'
            className='button'
            onClick={(e) => { window.open("/new", "_self"); }}
          >
            Novo compromisso
          </Button>
        </div>
      </div>

      {
        hasError && (
          <ErrorContainer>
            <span>
              Ocorreu um erro ao retornar os compromissos
            </span>
            <Button
              type="button"
              secodary
              onClick={(e) => { handleReload(); }}
            >
              Tentar novamente
            </Button>
          </ErrorContainer>
        )
      }

      {(calendarAppointments && calendarAppointments.Weeks) && (
        <div className='navigation-buttons-container'>
          <div
            className="button-container"
          >
            <Button
              type='button'
              secondary
              className='button secondary navigation'
              key='1'
              onClick={(e) => {
                // const date = new Date(calendarAppointments.PreviousMonthDate).toISOString().split('T')[0];
                // window.open(`/cherry-pick/${date}`, "_self");
                handleChangeSelectedDate(calendarAppointments.PreviousMonthDate)
              }}
            >
              {calendarAppointments.PreviousMonthDate ? `< ${calendarAppointments.PreviousMonthName}` : ''}

            </Button>
          </div>


          <div
            className="button-container"
          >
            <Button
              type='button'
              secondary
              className='button secondary navigation current-month-name'
              key='3'
            >
              {calendarAppointments.SelectedDate ? `${calendarAppointments.SelectedDateMonthName}` : ''}
            </Button>
          </div>

          <div
            className="button-container"
          >
            <Button
              type='button'
              secondary
              className='button secondary navigation'
              key='2'
              onClick={(e) => {
                // const date = new Date(calendarAppointments.NextMonthDate).toISOString().split('T')[0];
                // window.open(`/cherry-pick/${date}`, "_self");
                handleChangeSelectedDate(calendarAppointments.NextMonthDate)
              }}
            >
              {calendarAppointments.NextMonthDate ? `${calendarAppointments.NextMonthName} >` : ''}
            </Button>
          </div>
        </div>
      )}

      <div className='week-names-row-container'>
        <div className='week-names-row'>
          <div className='week-name'>
            DOM
          </div>
          <div className='week-name'>
            SEG
          </div>
          <div className='week-name'>
            TER
          </div>
          <div className='week-name'>
            QUA
          </div>
          <div className='week-name'>
            QUI
          </div>
          <div className='week-name'>
            SEX
          </div>
          <div className='week-name'>
            SÁB
          </div>
        </div>
      </div>

      <div className='week-rows-container'>
        {(calendarAppointments && calendarAppointments.Weeks) && (
          calendarAppointments.Weeks.map((week, i) => (
            <div
              key={`weeks-${i}`}
              className='week-row-container'
            >
              {(!week.IsActiveWeek) ? (

                <div className='week-days-container'
                  key={`week-${i}`}
                >
                  {week.WeekDays.map((weekDay) => (
                    <>
                      {!weekDay.IsNullDay && (
                        <div
                          key={`activeweekday-${weekDay.Day}`}
                          className={`week-day ${weekDay.HasAppointments && ('has-appointments')} ${weekDay.IsToday && ('today')}`}
                          onClick={(e) => {
                            handleChangeSelectedDate(weekDay.DayDate)
                          }}
                        >
                          {weekDay.Day}
                        </div>
                      )}

                      {weekDay.IsNullDay && (
                        <div
                          key={`day-${weekDay.Day}`}
                          className={`null-day`}
                        >
                        </div>
                      )}
                    </>
                  ))}
                </div>

              ) : (
                <>
                  <div className='week-days-container active'>
                    <div
                      className='week-day active'
                      onClick={(e) => {
                        const date = new Date(activeDay.DayDate).toISOString().split('T')[0];
                        window.open(`/cherry-pick/${date}`, "_self");
                      }}
                    // onClick={(e) => {
                    //   // handleChangeSelectedDate(weekDay.DayDate)
                    // }}
                    >
                      <div className='number'>
                        {activeDay.Day}
                      </div>

                      <div className='appointments-list active'>
                        {(activeDay.Appointments) &&
                          (
                            <>
                              {(activeDay.Appointments[0]) && (
                                <div
                                  key={`id-${activeDay.Appointments[0].Id}`}
                                  className='appointment'>
                                  {activeDay.Appointments[0].AppointmentTime && (
                                    activeDay.Appointments[0].AppointmentTime
                                  )}
                                  {activeDay.Appointments[0].Name && (
                                    ` - ${activeDay.Appointments[0].Name}`
                                  )}
                                </div>
                              )}
                              {(activeDay.Appointments[1]) && (
                                <div
                                  key={`id-${activeDay.Appointments[1].Id}`}
                                  className='appointment'>
                                  {activeDay.Appointments[1].AppointmentTime && (
                                    activeDay.Appointments[1].AppointmentTime
                                  )}
                                  {activeDay.Appointments[1].Name && (
                                    ` - ${activeDay.Appointments[1].Name}`
                                  )}
                                </div>
                              )}
                              {(activeDay.Appointments[2]) && (
                                <div
                                  key={`id-${activeDay.Appointments[2].Id}`}
                                  className='appointment'>
                                  {activeDay.Appointments[2].AppointmentTime && (
                                    activeDay.Appointments[2].AppointmentTime
                                  )}
                                  {activeDay.Appointments[2].Name && (
                                    ` - ${activeDay.Appointments[2].Name}`
                                  )}
                                </div>
                              )}
                            </>

                          )}
                      </div>

                      {(activeDay.Appointments.length > 3 && (
                        <div className='remaining-appointments'>
                          {(activeDay.Appointments.length > 3 && (
                            <>
                              +{(activeDay.Appointments.length - 3)}
                            </>
                          ))}
                        </div>
                      ))}
                    </div>
                    {week.WeekDays.filter(f => f.IsNullDay !== true && (!f.IsActiveDay)).map((weekDay, i, row) => (
                      <>
                        {((weekDay.Day !== activeDay.Day) && (!weekDay.IsNullDay)) && (
                          <div
                            key={`weekday-${weekDay.Day}`}
                            // className='week-day'
                            className={`week-day ${getWeekDayWidth(row.length, (i + 1 === row.length))}`}
                            onClick={(e) => {
                              const date = new Date(weekDay.DayDate).toISOString().split('T')[0];
                              window.open(`/cherry-pick/${date}`, "_self");
                            }}
                          >
                            <div className='number'>
                              {weekDay.Day}
                            </div>

                            <div className='appointments-list'>
                              {(weekDay.Appointments) && (
                                <>
                                  {(weekDay.Appointments[0]) && (
                                    <div
                                      key={`id-${weekDay.Appointments[0].Id}`}
                                      className='appointment'>
                                      {weekDay.Appointments[0].AppointmentTime && (
                                        weekDay.Appointments[0].AppointmentTime
                                      )}
                                      {weekDay.Appointments[0].Name && (
                                        ` - ${weekDay.Appointments[0].Name}`
                                      )}
                                    </div>
                                  )}
                                  {(weekDay.Appointments[1]) && (
                                    <div
                                      key={`id-${weekDay.Appointments[1].Id}`}
                                      className='appointment'>
                                      {weekDay.Appointments[1].AppointmentTime && (
                                        weekDay.Appointments[1].AppointmentTime
                                      )}
                                      {weekDay.Appointments[1].Name && (
                                        ` - ${weekDay.Appointments[1].Name}`
                                      )}
                                    </div>
                                  )}
                                  {(weekDay.Appointments[2]) && (
                                    <div
                                      key={`id-${weekDay.Appointments[2].Id}`}
                                      className='appointment'>
                                      {weekDay.Appointments[2].AppointmentTime && (
                                        weekDay.Appointments[2].AppointmentTime
                                      )}
                                      {weekDay.Appointments[2].Name && (
                                        ` - ${weekDay.Appointments[2].Name}`
                                      )}
                                    </div>
                                  )}
                                </>
                              )}

                            </div>

                            {(weekDay.Appointments) && (
                              <>
                                {(weekDay.Appointments.length > 3 && (
                                  <div className='remaining-appointments'>
                                    {(weekDay.Appointments.length > 3 && (
                                      <>
                                        +{(weekDay.Appointments.length - 3)}
                                      </>
                                    ))}
                                  </div>
                                ))}
                              </>)}
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                </>
              )}
            </div>
          ))
        )}
      </div>
    </Container >
  );
}

CalendarVision.propTypes = {
  selectedDate: PropTypes.string.isRequired,
  setSelectedDate: PropTypes.func.isRequired
}