import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import {
  Overlay,
} from './styles';
import Button from '../Button';
// import Input from '../Input';
import CloseModalButton from '../../assets/icons/close-modal.svg'

export default function ModalCancelUserRegistration({ title, body, onConfirm, onCancel, isVisible }) {
  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Overlay>
        <div
          className='modal-container full'
        >
          <div className='modal-title'>
            <div className='text danger'>{title}</div>
            <div className='close-button'>
              <button
                onClick={onCancel}
              >
                <img src={CloseModalButton} alt='telembro' />
              </button>
            </div>
          </div>
          <div className='modal-body full'>
            <div
              className='terms-content'
              dangerouslySetInnerHTML={{ __html: (body) }}
            />
          </div>
          <div
            className='button-container vertical-buttons'
          >
            <Button
              type='button'
              danger
              onClick={onConfirm}
              className='button danger'
            >
              Confirmo o cancelamento
            </Button>
            <Button
              type='button'
              onClick={onCancel}
              className='button secondary'
              secondary
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Overlay>
    </>,
    document.getElementById('modal-root')
  );
}

ModalCancelUserRegistration.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

ModalCancelUserRegistration.defaultProps = {
  isVisible: false,
}