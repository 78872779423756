import styled from "styled-components";

export const Container = styled.header`
  margin-bottom: 24px;
  /* height: 54px;
  flex-shrink: 0;
  background: var(--telembro-laranja, #F25C05);
  color: #FFF;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-left: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9; */

  a{
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: left;
    width: 100%;
    max-width: 70px;
    /* margin: auto; */

    span{
      color: ${({ theme }) => theme.colors.primary.main};
      font-weight: bold;
    }

    img{
      margin: auto;
      width: 16px;
      margin-right: 8px;
    }
  }

  h1{
    font-size: 24px;
    /* margin: auto; */
  }
`;