import { NumberFormatBase } from "react-number-format";
// import PropTypes from 'prop-types';

export default function MyCustomNumberFormat(
  { id,
    name,
    className,
    placeholder,
    value,
    onValueChange
  }) {
  const format = (numStr) => {
    if (!Number(numStr)) return "";

    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(numStr / 100);
  };

  return (
    <NumberFormatBase
      id={id}
      name={name}
      className={className}
      placeholder={placeholder}
      value={value}
      onValueChange={onValueChange}
      format={format} />
  );
}

// MyCustomNumberFormat.propTypes = {
//   props: PropTypes.shape,
// }