import styled, { css } from "styled-components";

export const Form = styled.form`
  div{
    &.form{
      padding-bottom: 40px !important;
      /* margin-bottom: 8px; */
      font-weight: bold;
      color: ${({ theme }) => theme.colors.gray[900]};

      span{
      margin-right: 16px;
      }

      label{
      margin-right: 16px;
      cursor: pointer;
      }

      #react-select-3-placeholder{
        color: #CECECE !important;
      }

      #document-value{
        width: 100%;
        background: #FFF;
        box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
        height: 52px;
        border: 2px solid #FFF;
        border-radius: 4px;
        outline: none;
        padding: 0 16px;
        font-size: 16px;
        transition: border-color 0.2s ease-in;
        appearance: none;

        &:focus{
          border: 2px solid ${({ theme }) => theme.colors.primary.main};
        }

        ${({ theme, error }) => error && css`
        color: ${theme.colors.danger.main};
        border-color: ${theme.colors.danger.main} !important;

        &:focus{
          color: ${theme.colors.danger.light};
          border-color: ${theme.colors.danger.main} !important;
        }
      `};
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;

  button{
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 700;
  }
`;