import React, { useState } from "react";
import Input from "../Input";

export default function InputAuto({
  // label,
  pholder,
  data,
  // onSelected,
  onChange
}) {
  const [suggestions, setSugesstions] = useState([]);
  const [isHideSuggs, setIsHideSuggs] = useState(false);
  const [selectedVal, setSelectedVal] = useState("");

  const handler = e => {
    setSugesstions(data.filter(i => i.startsWith(e.target.value)));
  };

  const handleChange = e => {
    const input = e.target.value;

    if (!input) {
      setIsHideSuggs(true);
    }
    else {
      setIsHideSuggs(false);
    }

    setSelectedVal(input);
    // onChange(e);
  };

  const hideSuggs = (e, value) => {
    // setSelectedVal(value);
    e.target.value = value;
    handleChange(e);
    setIsHideSuggs(true);
    onChange(e);
    setSelectedVal("");
  };

  const handleKeyDown = evt => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = evt.target.value.trim();
      evt.target.value = value;

      onChange(evt);
      setSelectedVal("");
      setIsHideSuggs(true);

    }
  };

  return (
    <div className="sugesstion-auto">
      <div className="form-control-auto">
        <Input
          placeholder={pholder}
          type="search"
          value={selectedVal}
          onChange={handleChange}
          onKeyUp={handler}
          onKeyDown={handleKeyDown}
        />
      </div>

      <div
        className="suggestions"
        style={{ display: isHideSuggs ? "none" : "block" }}
      >
        {suggestions.map((item, idx) => (
          <div
            key={"" + item + idx}
            onClick={(e) => {
              hideSuggs(e, item);
            }}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}
