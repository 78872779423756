import styled from "styled-components";

export const Form = styled.form`
  div{
    margin-bottom: 8px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray[900]};
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;
  text-align: center;

  .small-legal-disclaimer{
    margin-top: 4px;
    /* color: var(--telembro-laranja, #F25C05); */
    color: #6095A0;
    font-size: 8px;

    a{
      /* color: var(--telembro-laranja, #F25C05); */
      color: #6095A0;
    }
  }

  img{
    margin: auto;
  }

  button{
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 700;
  }
`;

export const Container = styled.div`
  margin: auto;
  margin-top: 32px;
  padding-bottom: 32px;
  text-align: center;
`;