import styled, { css } from "styled-components";

export default styled.button`
  background: var(--telembro-laranja, #F25C05);
  color: #FFF;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
  height: 40px;
  border: 1px solid var(--telembro-laranja, #F25C05);
  border-radius: 10px !important;
  outline: none;
  padding: 0 16px;
  font-size: 16px;
  transition: background all 0.2s ease-in;
  cursor: pointer;

  /* &:hover{
    background: ${({ theme }) => theme.colors.primary.light};
  }

  &:active{
    background: ${({ theme }) => theme.colors.primary.dark};
  } */

  &[disabled]{
    /* background: #CCC; */
    background: #CCC;
    border: 2px solid #CCC;
    cursor: default;
  }

  ${({ theme, danger }) => danger && css`
    background: ${theme.colors.danger.main};

    &:hover{
      background: ${theme.colors.danger.light};

      &[disabled]{
        background: #CCC;
        border: 2px solid #CCC;
        cursor: default;
      }
    }

    &:active{
      background: ${theme.colors.danger.dark};
    }
  `};

  ${({ theme, secondary }) => secondary && css`
    /* background: ${theme.colors.danger.main}; */
    border-radius: 10px;
    border: 1px solid var(--telembro-laranja, #F25C05);
    background: var(--telembro-laranja-l, #FFF1E9);

    color: var(--telembro-laranja, #F25C05);
    text-align: center;

    /* TL-botao */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `};

  ${({ theme, cancel }) => cancel && css`
    /* background: ${theme.colors.danger.main}; */
    border-radius: 10px;
    border: 1px solid var(--telembro-laranja, #F25C05);
    background: var(--telembro-laranja-l, #FFFFFF);

    color: #6095A0;
    text-align: center;

    /* TL-botao */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `};
`;