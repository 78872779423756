import { useState } from 'react';
import {
  Form,
  ButtonContainer,
  // Container
} from './styles';
import FormGroup from '../FormGroup';
import Input from '../Input';
import TextArea from '../TextArea';
import Button from '../Button';
import useErrors from '../../hooks/useErrors';
import Loader from '../Loader';

export default function AppVersionForm({
  appVersion,
  onSubmit,
}) {
  const [versionNumber, seVersionNumber] = useState(appVersion.VersionNumber);
  const [versionDetails, setVersionDetails] = useState(appVersion.VersionDetails);
  // const [isCurrentVersion, setIsCurrentVersion] = useState(appVersion.IsCurrentVersion);

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = (versionNumber && versionDetails && errors.length === 0);
  const [isLoading, setIsLoading] = useState(false);
  // const [isCreateUserSuccess, setIsCreateUserSuccess] = useState(false);
  // const [serviceError, setServiceError] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);

    const response = await
      onSubmit({
        versionNumber,
        versionDetails,
      });

    if (response.Error) {
      // setServiceError(response.Error.Message);
      alert(response.Error.Message);
    }
    // else {
    //   setServiceError(null);
    // }

    // setIsCreateUserSuccess(true);
    // setIsLoading(false);
    handleGoToList();
  }

  async function handleGoToList() {
    window.open('/app-versions', '_self');
  }

  function handleChangeVersionNumber(e) {
    seVersionNumber(e.target.value);

    if (!e.target.value) {
      setError({ field: 'versionNumber', message: 'o nome da versão é obrigatório' });
    } else {
      removeError('versionNumber');
    }
  }

  function handleChangeVersionDetails(e) {
    setVersionDetails(e.target.value);

    if (!e.target.value) {
      setError({ field: 'versionDetails', message: 'os detalhes são obrigatórios' });
    } else {
      removeError('versionDetails');
    }
  }

  return (
    <Form onSubmit={handleSubmit} noValidate >
      <FormGroup
        error={getErrorMessageByFieldName('versionNumber')}
      >
        <div className='form-label'>Nome da versão</div>
        <Input
          type="text"
          value={versionNumber}
          placeholder="Nome da versão"
          onChange={handleChangeVersionNumber}
          error={getErrorMessageByFieldName('versionNumber')}
        />
      </FormGroup>

      <FormGroup
        error={getErrorMessageByFieldName('versionDetails')}
        style={{
          height: '300px',
          maxHeight: '300px',
        }}
      >
        <div
          className='form-label'
          style={{
            width: '90%',
            background: 'white',
            height: '25px',
            marginTop: '-6px',
            paddingTop: '6px'
          }}
        >Detalhes da versão</div>
        <TextArea
          type="text"
          value={versionDetails}
          placeholder="Detalhes da versão"
          onChange={handleChangeVersionDetails}
          error={getErrorMessageByFieldName('versionDetails')}
          style={{
            height: '300px',
            maxHeight: '300px',
            paddingTop: '50px'
          }}
        />
      </FormGroup>

      <Loader isLoading={isLoading} />

      <ButtonContainer>
        <Button
          type="button"
          disabled={!isFormValid}
          onClick={(e) => handleSubmit(e)}
        >
          Salvar
        </Button>
      </ButtonContainer>

    </Form>
  );
}