import { useState } from 'react';
import { Container } from './styles';
import useErrors from '../../hooks/useErrors';
import { Form, ButtonContainer } from './styles';
// import PageHeader from '../../components/PageHeader';
import FormGroup from '../../components/FormGroup';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Config from '../../config/environments/local';
import UsersService from '../../services/UsersService';
import Loader from '../../components/Loader';
import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'

export default function ForgotPassword() {

  const [email, setEmail] = useState('');
  const isFormValid = (email);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetPaswordSuccess, setIsResetPaswordSuccess] = useState(false);
  const [serviceError, setServiceError] = useState('');

  const {
    // errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  function handleChangeEmail(e) {
    setEmail(e.target.value);

    if (!e.target.value) {
      setError({ field: 'email', message: 'o e-mail é obrigatório' });
    } else {
      removeError('email');
    }
  }

  async function handleGoToLogin(e) {
    e.preventDefault();
    e.stopPropagation();

    window.open('/login', '_self');
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    const response = await new UsersService(Config.API_BASE_URL).ResetPassword({
      Email: email,
    });

    if (response.Error) {
      setError({ field: 'email', message: response.Error.Message });
      setServiceError(response.Error.Message);
      setIsResetPaswordSuccess(true);
    } else {
      setIsResetPaswordSuccess(true);
      removeError('email');
    }

    setIsLoading(false);

  }

  return (
    <>
      {(!isResetPaswordSuccess) ? (
        <>
          <div className="no-results-image-container">
            <div className='image'>
              <img src={CalendarImage} alt='logo' />
            </div>

            <div className='text'>
              Não se preocupe!
              <br />
              <br />
              Digite seu email cadastrado para recuperarmos sua senha.
              <br />
              <br />
              Será enviado um link para o seu email.
            </div>
          </div>

          <div style={{ height: '27px' }}></div>
          <Form onSubmit={handleSubmit} noValidate>
            <FormGroup
              error={getErrorMessageByFieldName('email')}
            >
              <div className='form-label'>Informe seu e-mail cadastrado</div>
              <Input
                type="text"
                value={email}
                placeholder="E-mail"
                onChange={handleChangeEmail}
                error={getErrorMessageByFieldName('email')}
              />
            </FormGroup>

            <Loader isLoading={isLoading} />

            <ButtonContainer>
              <Button
                type="button"
                disabled={!isFormValid}
                onClick={(e) => handleSubmit(e)}

              >
                Recuperar senha
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button
                type='button'
                secondary
                onClick={() => window.open(`/`, '_self')}
              >
                Cancelar
              </Button>
            </ButtonContainer>
          </Form >
        </>
      ) : (
        <Container>
          <div className="no-results-image-container">
            <div className='image'>
              <img src={CalendarImage} alt='logo' />
            </div>

            {/* {(serviceError) ? (
              <div className='text'>
                {serviceError}
              </div>
            ) : (
              <div className='text'>
                E-mail enviado com sucesso para [{email}]
                <br />
                <br />
                Siga as instruções e cadastre uma nova senha
              </div>
            )} */}

            <div className='text'>
              Caso este usuário exista, este e-mail [{email}] receberá uma mensagem com instruções para recuperar sua senha
            </div>
          </div>

          <div style={{ height: '27px' }}></div>

          {(serviceError) ? (
            <ButtonContainer>
              <Button
                type="button"
                onClick={(e) => setIsResetPaswordSuccess(false)}
              >
                Alterar o e-mail e tentar novamente
              </Button>
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <Button
                type="button"
                onClick={(e) => handleGoToLogin(e)}
              >
                Voltar para login
              </Button>
            </ButtonContainer>
          )}
        </Container>
      )}


    </>
  )
};