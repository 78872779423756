// import ShieldI from '../../assets/icons/shield_i.svg'
import {
  useState,
  useEffect,
  useCallback,
} from "react";
import FormGroup from "../../components/FormGroup";
import { localGet } from '../../lib/session';
import Config from "../../config/environments/local";
import TimezoneService from "../../services/TimezoneService";
import Loader from "../../components/Loader";
import Select from "../../components/Select";

export default function Timezones() {

  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [timezones, setTimezones] = useState([]);
  const [userTimezoneConfigurations, setUserTimezoneConfigurations] = useState([]);
  const [userTimezoneId, setUserTimezoneId] = useState((userTimezoneConfigurations && userTimezoneConfigurations.TimezoneId) ?? null);
  const [automaticallyGetUserTimezone, setAutomaticallyGetUserTimezone] = useState(userTimezoneConfigurations.AutomaticallyGetUserTimezone ?? false);
  const [isLoading, setIsLoading] = useState(false);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const GetUserTimezoneConfigurations = useCallback(async () => {
    try {
      setIsLoading(true);
      const getConfigurationsResponse = await new TimezoneService(Config.API_BASE_URL).GetUserConfigurations({
        UserId: sessionObject.userId,
      });

      setUserTimezoneConfigurations(getConfigurationsResponse.Result);

      setAutomaticallyGetUserTimezone(getConfigurationsResponse.Result.AutomaticallyGetUserTimezone);
      setUserTimezoneId(getConfigurationsResponse.Result.TimezoneId);

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject.userId]);

  const getTimezones = useCallback(async () => {
    try {
      setIsLoading(true);
      const getConfigurationsResponse = await new TimezoneService(Config.API_BASE_URL).GetAll();

      setTimezones(getConfigurationsResponse.Result);

      // setIsAllowedGlobalTimezone(getConfigurationsResponse.Result.AllowInGeneral);
      // setIsAllowedForAppointmentsTimezone(getConfigurationsResponse.Result.AllowForAllAppointments);

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const UpdateUserTimezone = useCallback(async () => {
    try {
      // setIsLoading(true);
      await new TimezoneService(Config.API_BASE_URL).UpdateUserTimezone({
        UserId: sessionObject.userId,
        TimezoneString: timeZone,
      });

      await GetUserTimezoneConfigurations();
      // alert("Configurações de timezone atualizadas com sucesso!");

    } catch (error) {
      console.log('error ', error);
    } finally {
      // setIsLoading(false);
    }
  }, [GetUserTimezoneConfigurations, sessionObject.userId, timeZone]);

  useEffect(() => {
    console.log(timeZone);

    if (timeZone && (sessionObject.localTimezoneString !== timeZone)) {
      UpdateUserTimezone();
    }

    getTimezones();
    GetUserTimezoneConfigurations();
  }, [getTimezones, GetUserTimezoneConfigurations, timeZone, sessionObject.localTimezoneString, UpdateUserTimezone]);

  async function ChangeAutomaticallyGetUserTimezone() {

    if (automaticallyGetUserTimezone) {
      setUserTimezoneId(null);
      await new TimezoneService(Config.API_BASE_URL).ActivateAutomaticallyGetUserTimezone({
        UserId: sessionObject.userId,
      });
    } else {
      await new TimezoneService(Config.API_BASE_URL).DeactivateAutomaticallyGetUserTimezone({
        UserId: sessionObject.userId,
        TimeZoneId: userTimezoneId,
      });
    }

    await GetUserTimezoneConfigurations();
    alert("Configurações de timezone atualizadas com sucesso!");
  };

  function handleChangeAutomaticallyGetUserTimezone(e) {
    const inputValue = e.target.checked;
    const AutomaticallyGetUserTimezone = (inputValue === true);

    setAutomaticallyGetUserTimezone(AutomaticallyGetUserTimezone);
    // ChangeAutomaticallyGetUserTimezone(IsAllowedGlobalTimezone)

  }

  function handleChangeUserTimezoneId(e) {
    const inputValue = e.target.value;

    setUserTimezoneId(inputValue);
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className='info-page'>
        <div className="big-text">
          <div className=''>
            <div className="text-header">
              Configuração de fuso horário
            </div>

            {/* <div className="big-text-header">
              Configuração de fuso horário
            </div> */}
            <>
              <FormGroup
              // error={getErrorMessageByFieldName('appointmentRecurrenceTypeId')}
              >
                <div className='form-label'>Localidade</div>
                <Select
                  value={userTimezoneId}
                  onChange={handleChangeUserTimezoneId}
                  // error={getErrorMessageByFieldName('appointmentRecurrenceTypeId')}
                  placeholder='Selecione um fuso'
                  disabled={automaticallyGetUserTimezone}
                >
                  <option value="">Selecione um fuso</option>
                  {timezones && (
                    timezones.map((timezone) => (
                      <option key={timezone.Id} value={timezone.Id}>{timezone.TimeZoneName}</option>
                    )))}
                </Select>
              </FormGroup>
              <FormGroup
                className='toggle'
              >
                <div className='side-label'>Configurar automaticamente</div>
                <div
                  className='toggleWrapper'
                >
                  <input
                    id={`g-allow`}
                    type="checkbox"
                    className='mobileToggle'
                    key={`g-allow`}
                    checked={(automaticallyGetUserTimezone === true)}
                    onChange={handleChangeAutomaticallyGetUserTimezone}
                  />
                  <label htmlFor={`g-allow`}></label>
                </div>
              </FormGroup>
            </>
            O fuso estipulado acima afetará <b>todos</b> os seus compromissos (novos e já existentes).
            <br />
            <br />
            Com a opção "confgurar automaticamente"
            ativa, seu fuso no TeLembro será alterado
            conforme a localização efetiva do seu
            dispositivo mudar, e também afetará <b>todos</b> os seus compromissos.
          </div>
        </div>


        <div
          className="button-container"
        >
          <button
            type='button'
            className='button'
            onClick={() => ChangeAutomaticallyGetUserTimezone()}
            onKeyDown={() => ChangeAutomaticallyGetUserTimezone()}
          >
            Confirmar
          </button>
        </div>
        <div
          className="button-container"
        >
          <button
            type='button'
            className='button secondary'
            onClick={() => window.open('/', '_self')}
            onKeyDown={() => window.open('/', '_self')}
          >
            Voltar
          </button>
        </div>
      </div>
    </>
  )
};