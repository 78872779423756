import HttpClient from './utils/HttpClient';

class TimezoneService {
  constructor(baseUrl) {

    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async GetAll() {
    const urlPath = `/timezoneconfigurations/get-all`;

    return this.httpClient.get(urlPath);
  }

  async GetUserConfigurations(data) {
    const urlPath = `/timezoneconfigurations/users/get`;

    return this.httpClient.post(urlPath, data);
  }

  async UpdateUserTimezone(data) {
    const urlPath = `/timezoneconfigurations/users/update`;

    return this.httpClient.post(urlPath, data);
  }

  async ActivateAutomaticallyGetUserTimezone(data) {
    const urlPath = `/timezoneconfigurations/activate/automaticallygetusertimezone`;

    return this.httpClient.post(urlPath, data);
  }
  async DeactivateAutomaticallyGetUserTimezone(data) {
    const urlPath = `/timezoneconfigurations/deactivate/automaticallygetusertimezone`;

    return this.httpClient.post(urlPath, data);
  }
}

export default TimezoneService;