// import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'
import ShieldI from '../../assets/icons/shield_i.svg'
// import ShieldPerson from '../../assets/icons/shield_person.svg'

export default function Privacy() {
  return (
    <>
      <div className='info-page'>
        <div className="image-header icon">
          <img src={ShieldI} alt='telembro' />
        </div>
        <div className="text-header">
          Sua privacidade, seus direitos e o nosso compromisso
        </div>

        <div className="big-text-header">
          Sua privacidade: quais são os seus direitos?
        </div>

        <div className="big-text">
          Você tem o direito de Acesso, Atualização, corrija e tenha confidencialidade sobre suas informações pessoais. Você também pode nos pedir para parar de enviar anúncios, ofertas e promoções, excluir seus dados e opor-se a nós usando seus dados para um ou mais propósitos. Cada legislação prevê esses direitos de forma diferente, por isso recomendamos que você leia o anexo que corresponde ao país onde você vive.
          <br />
          <br />
          Pode acontecer que, como cumprimos algo declarado na declaração de privacidade ou por dever legal, talvez tenhamos que armazenar algumas de suas informações pessoais que você nos pediu para excluir. Depois que esses requisitos forem atendidos, excluiremos suas informações.
          <br />
          <br />
          A privacidade online requer trabalho conjunto entre empresas e usuários. Temos fortes medidas de segurança para proteger suas informações, precisamos que você cuide de sua privacidade, também.
          <br />
          <br />
          Se você quiser fazer uma reclamação sobre como processamos seus dados pessoais, você pode entrar em contato com a autoridade de supervisão apropriada em seu país. No anexo do seu país você pode encontrar mais informações sobre como e antes de quem fazer a reivindicação e como exercer seus direitos.
          <br />
          <br />
          <div className='important'>
            Suas informações pessoais estão protegidas. Não vendemos ou comercializamos informações que possam identificá-lo.
          </div>
          <br />
          Também não compartilhamos ou transferimos de nenhuma outra forma suas informações pessoais para terceiros, com exceção dos prestadores de serviços (terceiros que contratamos para fornecer um serviço seguindo nossas instruções), parceiros comerciais, outras empresas do nosso grupo, outros usuários, autoridades competentes, entre outros.

        </div>


        <div
          className="button-container"
        >
          <button
            type='button'
            className='button secondary'
            onClick={() => window.open('/about', '_self')}
            onKeyDown={() => window.open('/about', '_self')}
          >
            Voltar
          </button>
        </div>
      </div>
    </>
  )
};