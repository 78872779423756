/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Container,
  Card,
  MessageCard,
  ErrorContainer,
  CardsContainer,
  MessageCardsContainer,
  // Form,
} from './styles';
// import FormGroup from "../../components/FormGroup";
// import Select from "../../components/Select";
import Loader from '../Loader';
import {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { format } from 'date-fns';
import Button from '../Button';
import TextArea from '../TextArea';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
// import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'
import Calendar from '../../assets/icons/event.svg';
import SendMessageButton from '../../assets/icons/send-message-button.svg';
import RecurrenceIcon from '../../assets/icons/event_repeat.svg';
// import Notifications from '../../assets/icons/notifications.svg';
// import Info from '../../assets/icons/info.svg';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
// faCheckCircle,
// faTimesCircle,
// } from '@fortawesome/free-solid-svg-icons'
import SharedAppointmentMessagesService from '../../services/SharedAppointmentMessagesService';
import { useParams } from 'react-router-dom';

export default function SharedAppointmentMessages() {
  const bottomRef = useRef(null);

  const {
    appointmentId,
  } = useParams();

  const [appointmentConversation, setAppointmentConversation] = useState({});
  // const [approveSharedAppointments, setApprovedSharedAppointments] = useState([]);
  // const [showPending, setshowPending] = useState(true);
  // const [approvalTypeToShow, setApprovalTypeToShow] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError] = useState(false);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [message, setMessage] = useState('');
  const [messageCharCounter, setMessageCharCounter] = useState(0);

  const getConversation = useCallback(async () => {
    if (!sessionObject.userId) {
      return;
    }

    try {
      setIsLoading(true);
      if (appointmentId) {
        const appointmentMessagesResponse = await new SharedAppointmentMessagesService(Config.API_BASE_URL).get(appointmentId, sessionObject.userId);
        if (appointmentMessagesResponse.Result) {
          setAppointmentConversation(appointmentMessagesResponse.Result);
        }
        else {
          setAppointmentConversation({});
        }
      }

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject, appointmentId]);

  async function handleReload() {
    await getConversation();
  };

  useEffect(() => {
    if (!sessionObject) {
      window.open('/login', '_self');
    }

    getConversation();
  }, [getConversation, sessionObject]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [appointmentConversation]);

  async function handleSendMessage(e) {
    try {
      if (message.length > 0) {
        setIsLoading(true);
        await new SharedAppointmentMessagesService(Config.API_BASE_URL).send({
          AppointmentId: appointmentId,
          SenderUserId: sessionObject.userId,
          Message: message,
        });
        setMessage('');
        setMessageCharCounter(0);
        handleReload();
      }
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleChangeMessage(e) {

    if (e.target.value.length <= 400) {
      setMessage(e.target.value);
      setMessageCharCounter(e.target.value.length);
    }
  }

  function getChipClass(appointmentTYpeId) {
    switch (appointmentTYpeId) {
      case 1:
      case 2:
      case 3:
        return 'habilitacao-identidade-passaporte';
      case 4:
      case 5:
      case 6:
        return 'boleto-fatura-carne';
      case 7:
        return 'reuniao';
      case 8:
        return 'medico';
      default:
        return 'criada-usuario';
    }
  }

  return (
    <Container>
      <Loader isLoading={isLoading} />

      {/* <div className='info-page'>

      </div> */}

      <div className="fab-container">
        <div
          className='text-part'
        >
          <TextArea
            className='message'
            placeholder='Digite a mensagem aqui'
            value={message}
            onChange={handleChangeMessage}
            onKeyDown={(e) => {
              e.target.style.height = 'inherit';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
          >
          </TextArea>
          <div className='counter'>
            {`${messageCharCounter}/400`}
          </div>
        </div>
        <Button
          type='button'
          className='button'
          onClick={(e) => {
            // window.open("/new", "_self");
            handleSendMessage();
          }}
        >
          <img src={SendMessageButton} alt='enviar' />
        </Button>
      </div>

      {hasError && (
        <ErrorContainer>
          <strong>
            Ocorreu um erro ao retornar os compromissos
          </strong>
          <Button
            type="button"
            onClick={(e) => { handleReload(); }}
          >
            Tentar novamente
          </Button>
        </ErrorContainer>
      )}

      {!hasError && (
        <>
          <CardsContainer>
            {(appointmentConversation) && (
              <>
                {appointmentConversation.Appointment && (
                  <Card
                    key={`id-${appointmentConversation.Appointment.Id}`}
                  >
                    <div className="info">
                      <div className="text">
                        <div className="appointment-date-time">
                          <img src={Calendar} alt='date' />
                          {format(new Date(appointmentConversation.Appointment.AppointmentDate), 'dd/MM/yyyy')}

                          {appointmentConversation.Appointment.AppointmentTime && (
                            ` - ${appointmentConversation.Appointment.AppointmentTime}`
                          )}
                        </div>

                        <div className="appointment-name">
                          {appointmentConversation.Appointment.Name}
                        </div>

                        <div className='badges'>
                          <div className={`appointment-type-badge ${getChipClass(appointmentConversation.Appointment.AppointmentTypeId)}`}>
                            {appointmentConversation.Appointment.AppointmentTypeName}
                          </div>

                          {appointmentConversation.Appointment.AppointmentHasRecurrences && (
                            <div className="appointment-type-badge recorrente">
                              <img src={RecurrenceIcon} alt='Loop' />
                              Recorrente
                            </div>
                          )}
                        </div>


                        {/* {(appointmentConversation.Appointment.ReminderDates && appointmentConversation.Appointment.ReminderDates[0]) && (
                          <div className="span">
                            {
                              appointmentConversation.Appointment.ReminderDates[0] && (
                                <>
                                  <img src={Calendar} alt='date' />
                                  {format(new Date(appointmentConversation.Appointment.ReminderDates[0]), 'dd/MM/yyyy')}
                                </>
                              )
                            }

                            {
                              appointmentConversation.Appointment.ReminderDates[1] && (
                                <>
                                  , {format(new Date(appointmentConversation.Appointment.ReminderDates[1]), 'dd/MM/yyyy')}
                                </>
                              )
                            }

                            {
                              appointmentConversation.Appointment.ReminderDates[2] && (
                                <>
                                  &nbsp;e {format(new Date(appointmentConversation.Appointment.ReminderDates[2]), 'dd/MM/yyyy')}
                                </>
                              )
                            }

                          </div>
                        )} */}

                        {appointmentConversation.Appointment.Observations && (
                          <div className="appointment-observation">
                            {appointmentConversation.Appointment.Observations}
                          </div>
                        )}


                      </div>

                    </div>

                    {/* <div className="actions">
                      <a
                        href='#a'
                        className="confirm"
                        onClick={(e) => {
                          handleApprove(e, appointmentConversation.Id)
                        }}
                      >
                        <FontAwesomeIcon icon={faCheckCircle} />&nbsp;&nbsp;
                      </a>
                    </div> */}
                  </Card>
                )}
              </>
            )}
          </CardsContainer>

          <MessageCardsContainer
            className='messages-container'
          >
            {(appointmentConversation) && (
              <>
                {appointmentConversation.SharedAppointmentMessagesDateGrouped && (
                  <>
                    {appointmentConversation.SharedAppointmentMessagesDateGrouped.map((sharedAppointmentMessagesDateGrouped) => (
                      <>
                        <div className='messages-date'>
                          {sharedAppointmentMessagesDateGrouped.MessagesDateString}
                        </div>
                        {sharedAppointmentMessagesDateGrouped.SharedAppointmentMessages.map((sharedAppointmentMessage) => (
                          <MessageCard
                            key={`shared-appointment-message-${sharedAppointmentMessage.Id}`}
                            className={`${sharedAppointmentMessage.IsSenderMessage ? 'mine' : 'others'}`}
                          >
                            <div className="info">
                              <div className="text">
                                <div className="sender-name">
                                  {sharedAppointmentMessage.SenderUserName}
                                </div>
                                <div className="message-text">
                                  {sharedAppointmentMessage.Message}
                                </div>
                                <div className="message-time">
                                  {sharedAppointmentMessage.MessageHour}
                                </div>
                              </div>

                            </div>
                          </MessageCard>
                        ))}
                      </>
                    ))}
                  </>
                )}
              </>
            )}
            <div ref={bottomRef} className='conversation-bottom-ref' />
          </MessageCardsContainer>
        </>
      )}
      <div style={{ height: '75px' }}></div>
    </Container >
  );
}