import styled, { css } from "styled-components";

export default styled.textarea`  
  width: 100%;
  background: #FFF;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
  min-height: 56px;
  height: 56px;
  /* height: 156px; */
  border: none;
  border-bottom: 1px solid #BBB;
  border-radius: 4px 4px 0px 0px;
  outline: none;
  padding: 25px 16px 9px 16px;
  appearance: none;
  color: #636668 !important;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.36px;
  resize: none;
  max-height: 111px;
  
  &.small{
    /* max-width: 140px; */
    /* margin-left: 10px;
    margin-right: 10px; */

    /* &:last-of-type{
      margin-left: 10px;
    } */
  }

  &:focus{
    /* border: 2px solid ${({ theme }) => theme.colors.primary.main}; */
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.main};
  }

  ${({ theme, error }) => error && css`
    color: ${theme.colors.danger.main};
    border-color: ${theme.colors.danger.main} !important;

    &:focus{
      color: ${theme.colors.danger.light};
      border-color: ${theme.colors.danger.main} !important;
    }

  `};

  &.document-value{
    width: 100%;
    background: #FFF;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
    height: 52px;
    border: 2px solid #FFF;
    border-radius: 4px;
    outline: none;
    padding: 0 16px;
    font-size: 16px;
    transition: border-color 0.2s ease-in;
    appearance: none;

    &:focus{
      border: 2px solid ${({ theme }) => theme.colors.primary.main};
    }

    ${({ theme, error }) => error && css`
      color: ${theme.colors.danger.main};
      border-color: ${theme.colors.danger.main} !important;

      &:focus{
        color: ${theme.colors.danger.light};
        border-color: ${theme.colors.danger.main} !important;
      }

    `};
  }

  &.react-date-picker__inputGroup__input{
    width: 100%;
    background: #FFF;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
    height: 52px;
    border: 2px solid #FFF;
    border-radius: 4px;
    outline: none;
    padding: 0 16px;
    font-size: 16px;
    transition: border-color 0.2s ease-in;
    appearance: none;

    &:focus{
      border: 2px solid ${({ theme }) => theme.colors.primary.main};
    }

    ${({ theme, error }) => error && css`
      color: ${theme.colors.danger.main};
      border-color: ${theme.colors.danger.main} !important;

      &:focus{
        color: ${theme.colors.danger.light};
        border-color: ${theme.colors.danger.main} !important;
      }

    `};
  }
`;
